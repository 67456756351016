import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function Erreur() {
    const { t } = useTranslation();

    return (
        <div className="wrapper">
            <div>
                <div className="Bg-image1">
                <div className="overlay-thank1">
                    <div className="containerr1">
                    <h2 className="thankBigTitle1">{t('page404.title')}</h2>
                    <h4 className="title-ss">{t('page404.subtitle')}</h4>
                    <h4>{t('page404.para')}</h4> 
                    <Link to="/" className="mt-30 dsn-button image-zoom special-Link buttonThank1" data-dsn="parallax">
                                        <span className="dsn-border border-color-reverse-color spantxtbutton special-Link"></span>
                                        {t('page404.button')}  <span>⟶</span>
                        </Link>        
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Erreur
