import React from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
function Villa_renting_and_hotel_booking_in_Morocco() {
  return (
    <>
     <Helmet>
            <title>Villa renting and hotel booking in Morocco - EL ADANI VIP</title>
            <meta name="description" content="EL ADANI VIP find and book unique accommodations for you, we give you a wide range of rental luxury villas and charming homes as well as 5 stars hotel rooms to choose from." />
            <meta name="keywords" content="villa renting,villas for rent,hotel rooms booking,accommodation,luxury villas,houses for rent,5 stars hotel"></meta>
        </Helmet>
              <header
                    className="header-page over-hidden p-relative header-padding-top header-padding-bottom border-bottom dsn-header-animation">
                    <div className="bg-circle-dotted"></div>
                    <div className="dsn-container">
                        <div className="content-hero p-relative d-flex flex-column h-100 dsn-hero-parallax-title">
                        <h2> <strong className="title mt-30 dsn-load-animate text-transform-upper seo-page-h1">
                        Villa renting and hotel booking in Morocco 
                            </strong></h2>
                        </div>
                    </div>
                </header>  

                <div class="image-head p-relative">
                <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                    <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/lux_client.png" alt="Man in hotel" />
                </div>
            </div>
            <div className="wrapper">
                <br />  
                <div className="content_about seo-content">
                <h1 className="title mt-30 dsn-load-animate text-transform-upper seo-page-strong">
                Villa renting and hotel booking in Morocco 
                            </h1>
           
                    <div className="post-content">
                        <p className="">
                            Are you comming to Morocco or traveling from one Moroccan city to another, and you’re worried about your whereabouts ? since we take care of everything, we find and book unique accommodations for you, we give you a wide range of rental luxury villas and charming homes as well as 5 stars hotel rooms to choose from.
                        </p>
                    </div>
                    
                        <div className="p-services">
                        <Link to="/About" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                        <span className="dsn-border border-color-reverse-color"></span>
                        <span>⟵</span> Home Page
                    </Link>
                    <Link to="/contact" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                        <span className="dsn-border border-color-reverse-color"></span>
                        CONTACT US NOW
                    </Link>
                    
                </div></div>


            </div>
    </>
  )
}

export default Villa_renting_and_hotel_booking_in_Morocco