import React from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

function Plan_Website() {

    const { t } = useTranslation();

    return <>
        <Helmet>
            <title>Website Plan</title>
            <meta name="description" content="" />
        </Helmet>
        <header
            className="header-page over-hidden p-relative header-padding-top header-padding-bottom border-bottom dsn-header-animation">
            <div className="bg-circle-dotted"></div>
            <div className="dsn-container">
                <div className="content-hero p-relative d-flex flex-column h-100 dsn-hero-parallax-title">
                    <h2> <strong className="title mt-30 dsn-load-animate text-transform-upper seo-page-h1">
                        Website Plan
                    </strong></h2>
                </div>
            </div>
        </header>

        <div class="image-head p-relative">
            <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/lux_client.png" alt="Man in hotel" />
            </div>
        </div>
        <div className="content_about">
            <h1 className="title mt-30 dsn-load-animate text-transform-upper seo-page-strong">
                Website Plan
            </h1>
        </div>
        <div className="wrapper">
            <br />
        <div className="container-plan seo-content">
            <div className="card">
                <ul>
                <li><Link to="/" className="underline-ai special-Link special-Link">Home</Link></li>
                        <li><Link to="/About" className="underline-ai special-Link">About</Link></li>
                        <li><Link to="/Executive_Protection" className="underline-ai special-Link">Executive Protection</Link></li>
                        <li><Link to="/Global_Risk_Management" className="underline-ai special-Link">Global Risk Management</Link></li>
                        <li><Link to="/Investigation" className="underline-ai special-Link">Investigation</Link></li>
                        <li><Link to="/Concierge_Services" className="underline-ai special-Link">Concierge Services</Link></li>
                        <li><Link to="/Our_Fleet" className="underline-ai special-Link">Our Fleet</Link></li>
                        <li><Link to="/Contact" className="underline-ai special-Link">Contact</Link></li>
                </ul>
            </div>
            <div className="card">
                <ul>
                        <li><Link to="/Executive-protection-company-in-Morocco" className="underline-ai special-Link">Executive protection company in Morocco</Link></li>
                        <li><Link to="/Close-protection-services-in-Morocco" className="underline-ai special-Link">Close protection services in Morocco</Link></li>
                        <li><Link to="/Events-security-services-in-Morocco" className="underline-ai special-Link">Events security services in Morocco</Link></li>
                        <li><Link to="/VIPs-bodyguard-services-in-Morocco" className="underline-ai special-Link">VIPs bodyguard services in Morocco</Link></li>
                        <li><Link to="/VIPs-concierge-services-in-Morocco" className="underline-ai special-Link">VIPs concierge services in Morocco</Link></li>
                        <li><Link to="/Private-investigation-services-in-Morocco" className="underline-ai special-Link">Private investigation services in Morocco</Link></li>
                        <li><Link to="/Risk-assessement-and-risk-control-services-in-Morocco" className="underline-ai special-Link">Risk assessement and risk control services in Morocco</Link></li>
                        <li><Link to="/Private-jet-and-comercials-flight-booking-in-Morocco" className="underline-ai special-Link">Private jet and comercials flight booking in Morocco</Link></li>
                        <li><Link to="/Security-drivers-and-secure-transportation-services-in-Morocco" className="underline-ai special-Link">Security drivers and secure transportation services in Morocco</Link></li>
                        <li><Link to="/Villa-renting-and-hotel-booking-in-Morocco" className="underline-ai special-Link">Villa renting and hotel booking in Morocco</Link></li>
                </ul>
            </div>
        </div>
        </div>
    </>;
}

export default Plan_Website;
