import React from 'react'
import { useTranslation } from "react-i18next"

function Content_About() {
    const { t } = useTranslation();

    return (
        <>
            <div class="image-head p-relative">
                <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                    <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/Hotel_and_voiture.png" alt="Hotel and car" />
                </div>
            </div>
            <div>
                <br />  
                <div className="content_about">
                    <p className="sub-heading line-shap line-shap-after mb-15">
                        <span className="line-bg-left font-small-title">{t('about.title')}</span>
                    </p>
                    <br />
                    <div className="post-content">
                        <p>
                        {t('about.para1')}
                        <br /><br />
                        {t('about.para2')}
                        <br /><br />
                        {t('about.para3')}
                        <br /><br />
                        {t('about.para4')}
                        <br /><br />
                        {t('about.para5')}
                        </p>
                    </div>
                </div>

                <video className="services-video" controls>
                        <source src={process.env.PUBLIC_URL + '/assets/videos/Video_Eladani.mp4'} type="video/mp4" />
                        Your browser does not support the video tag.
                </video>
                
            </div>
        </>
    )
}

export default Content_About
