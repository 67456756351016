import React from 'react'
import { useTranslation } from "react-i18next"

function Content_ITS() {
    const { t } = useTranslation();
    return (
        <>
             <div class="image-head p-relative">
                <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                    <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/airport_client_with_bodyguard.png" alt="International travel security" />
                </div>
            </div>
            <div>
                <br />  
                <div className="content_about">
                    <p className="sub-heading line-shap line-shap-after mb-15">
                        <span className="line-bg-left font-small-title">{t('services.grm.title')}</span>
                    </p>
                    <br />
                    <div className="post-content p-services">
                        <p>
                        {t('services.grm.para1')}
                        <br /><br />
                        {t('services.grm.para2')}
                        <br /><br />
                        </p>
                        <ol>
                            <li className="Investigation-li"><p>{t('services.grm.li1')}</p></li>
                            <li className="Investigation-li"><p>{t('services.grm.li2')}</p></li>
                            <li className="Investigation-li"><p>{t('services.grm.li3')}</p></li>
                            <li className="Investigation-li"><p>{t('services.grm.li4')}</p></li>
                            <li className="Investigation-li"><p>{t('services.grm.li5')}</p></li>
                            {/* <li className="Investigation-li"><p>{t('services.grm.li6')}</p></li> */}
                        </ol>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Content_ITS
