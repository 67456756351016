import React,{useState} from 'react'
import { useTranslation } from "react-i18next"

function Content_Fleet() {

    const { t } = useTranslation();

    return (
        <>
            <div class="image-head p-relative">
                <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                    <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/CoverFleet.jpg" alt="Investigation" />
                </div>
            </div>
            

            <section className="our-blog container our-blog-classic not-filter section-margin p-relative dsn-swiper"
                        data-dsn-animate="section" data-dsn-option='{"slidesPerView":3,"spaceBetween":30 }'
                        data-dsn-title="our Blog">
                        <div className="container d-flex text-right flex-column align-items-center mb-70">
                            <div className="">
                                <h2 className="section-title line-bg-left">{t('fleet.title2')}</h2>
                               
                            </div>
                        </div>
                        <div className="All-vehicle">
                        <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Mercedes_S_Class.png"  alt="Mercedes S-Class" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Mercedes S-Class</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>


                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/range_rover_vogue.png"  alt="Range Rover Vogue" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Range Rover Vogue</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>


                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Mercedes_E_Class.png"  alt="Mercedes E-Class" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Mercedes E-Class</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>



                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Mercedes_V_Class.png"  alt="Mercedes V-Class" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Mercedes V-Class</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>


                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/volswagen_touareg.png"  alt="Volswagen Touareg" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Volswagen Touareg</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Jeep_Cheroukee.png"  alt="Jeep Cheroukee" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Jeep Grand Cheroukee</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>



                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Volvo_90_XC.png"  alt="Volvo 90 XC" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Volvo 90 XC</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Mercedes_Vito.png"  alt="Mercedes Vito" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Mercedes Vito</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>



                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Mercedes_Sprinter.png"  alt="Mercedes Sprinter" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Mercedes Sprinter</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>



                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Volvo_90_S.png"  alt="Volvo 90 S" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Volvo 90 S</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>


                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100 Container-vehicle">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Passat.png"  alt="Passat" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Passat</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>


                                    

                                    
                            </div>     


                        
            </section> 

            
        </>
    )
}

export default Content_Fleet
