import React from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

function Security_drivers_and_secure_transportation_services_in_Morocco() {
  return <>
   <Helmet>
            <title>Security drivers and secure transportation services in Morocco - El ADANI VIP</title>
            <meta name="description" content="EL ADANI VIP provide clients with reliable secure ground transportation in conjunction with executive protection services and airport meet and greet." />
            <meta name="keywords" content="Executive protection,bodyguard,VIP,safety,privacy security,security measures,risk, physical safty,financial safety,reputational safty,protection,close protection,security agent,personal risk,security procedures,personal protection, security firm,security company,risk assessement,advanced planning,crimes,threats,Itinerary planning,Morocco, secure transportation, risk control, protection officer, fleet, luxurious cars"></meta>
        </Helmet>
              <header
                    className="header-page over-hidden p-relative header-padding-top header-padding-bottom border-bottom dsn-header-animation">
                    <div className="bg-circle-dotted"></div>
                    <div className="dsn-container">
                        <div className="content-hero p-relative d-flex flex-column h-100 dsn-hero-parallax-title">
                        <h2> <strong className="title mt-30 dsn-load-animate text-transform-upper seo-page-h1">
                        Security drivers and secure transportation services in Morocco 
                            </strong></h2>
                        </div>
                    </div>
                </header>  

                <div class="image-head p-relative">
                <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                    <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/page_thank.png" alt="Man in hotel" />
                </div>
            </div>
            <div>
                <br />  
                <div className="content_about seo-content">
                <h1 className="title mt-30 dsn-load-animate text-transform-upper seo-page-strong">
                Security drivers and secure transportation services in Morocco
                            </h1>
           
                    <div className="post-content">
                        <p className="">
                        As an Executive Protection Company operating across Morocco. We provide clients with reliable secure ground transportation in conjunction with our executive protection services and airport meet and greet.
                        </p>
                        <p>
                        Our secure transportation service encompasses in-depth knowledge and a measured level of skill to conduct route surveys, recognize and develop safe havens, create alternate-route plans, develop emergency evacuation plans, and select the safest route to a destination in order to mitigate the risks and ensure that the clients arrives at their destination on time and in a comfortable manner.
                        </p>
                    </div>
                    <div>
                        <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                            Our mission
                                </h2>
                        <div className="post-content">
                            <p className="">
                            Our mission is to provide our clients an exceptional, luxury and comfortable service that comes with attention to detail and customer service standards that are second to none, by offering an excellent selection of luxury vehicles combined with knowledgeable, experienced, and accommodating professional security drivers alongside with our support staff 24/7- 365.                            </p>
                        </div>
                    </div>
                    <div>
                        <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                            Our security drivers qualities
                                </h2>
                        <div className="post-content">
                            <p className="">
                            Our security drivers are trained in a variety of safety and security techniques, they meet all strict background and safety checks and maintain exceptional driving record providing our clients peace of mind while in our care.
                            </p>
                        </div>
                    </div>
                    <div className="wrapper">
                        <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                            Our fleet
                                </h2>
                        <div className="post-content">
                            <p className="">
                                We provide the most luxurious service with an extensive range of luxurious, marked, and fully equipped vehicles that offer luxury, comfort and safety, Our fleet includes :
                            </p>
                            <ol>
                                <li className="Investigation-li">Mercedes ( S class - V class - E class )</li>
                                <li className="Investigation-li">Mercedes Vito</li>
                                <li className="Investigation-li">Mercedes sprinter</li>
                                <li className="Investigation-li">Rang rover Vogue</li>
                                <li className="Investigation-li button-li-p2"><Link to="/Our_Fleet"><p> See the entire fleet <span>⟶</span> </p></Link></li>
                            </ol>
                        </div>
                    </div>
                        <div className="p-services">
                        <Link to="/About" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                        <span className="dsn-border border-color-reverse-color"></span>
                        <span>⟵</span> Home Page
                        </Link>
                        <Link to="/contact" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                            <span className="dsn-border border-color-reverse-color"></span>
                            CONTACT US NOW
                        </Link>
                    
                </div></div>


            </div>
  </>;
}

export default Security_drivers_and_secure_transportation_services_in_Morocco;
