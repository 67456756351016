import React from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'


function Footer() {
    const { t } = useTranslation();
    return (

        <>
            <div className="top-footer">
                <div className="top-footer-left">
                    <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="Task" />
                    <p className="slogan" >WE TAKE CARE OF EVERYTHING</p>
                    <p className="subslogan">SAFETY - PRIVACY - LUXURY</p>
                </div>
                <div className="top-footer-right">
                    <h3>{t('footer.details')}</h3>
                    <p style={{ color: "#c2c2c2" }}> <span style={{ color: "#cd9b33" }}>{t('footer.adress')}:</span> {t('footer.bldv')}  </p>
                    <p>{t('footer.appartment')}</p>
                    <p>  <span style={{ color: "#cd9b33" }}>{t('footer.phone')}:</span>
                        <a href="tel:+212661812519" style={{ color: "#c2c2c2" }} className="">&nbsp;+212 661 812 519</a>
                    </p>
                    <p> <span style={{ color: "#cd9b33" }}> {t('footer.email')}</span> <a href="mailto:ops@eladanivip.com" style={{ color: "#c2c2c2" }} mailto="ops@eladanivip.com" className="">&nbsp;ops@eladanivip.com</a>
                    </p>

                </div>
            </div>
            <div className="top-footer">

            <div className="top-footer-left" style={{ justifyContent: "start" }}>
                <h3>QUICK ACCESS</h3>
                    <ul>
                        <li><Link to="/" className="underline-ai special-Link special-Link">Home</Link></li>
                        <li><Link to="/About" className="underline-ai special-Link">About</Link></li>
                        <li><Link to="/Executive_Protection" className="underline-ai special-Link">Executive Protection</Link></li>
                        <li><Link to="/Global_Risk_Management" className="underline-ai special-Link">Global Risk Management</Link></li>
                        <li><Link to="/Investigation" className="underline-ai special-Link">Investigation</Link></li>
                        <li><Link to="/Concierge_Services" className="underline-ai special-Link">Concierge Services</Link></li>
                        <li><Link to="/Our_Fleet" className="underline-ai special-Link">Our Fleet</Link></li>
                        <li><Link to="/Contact" className="underline-ai special-Link">Contact</Link></li>
                        <li><Link to="/Sitemap" className="underline-ai special-Link">Sitemap</Link></li>
                    </ul>

                </div>

                <div className="top-footer-right2 top-footer-right" style={{ justifyContent: "start" }} >

                <h3>POPULAR SEARCHES</h3>
                    <ul>
                        <li><Link to="/Executive-protection-company-in-Morocco" className="underline-ai special-Link">Executive protection company in Morocco</Link></li>
                        <li><Link to="/Close-protection-services-in-Morocco" className="underline-ai special-Link">Close protection services in Morocco</Link></li>
                        <li><Link to="/Events-security-services-in-Morocco" className="underline-ai special-Link">Events security services in Morocco</Link></li>
                        <li><Link to="/VIPs-bodyguard-services-in-Morocco" className="underline-ai special-Link">VIPs bodyguard services in Morocco</Link></li>
                        <li><Link to="/VIPs-concierge-services-in-Morocco" className="underline-ai special-Link">VIPs concierge services in Morocco</Link></li>
                        <li><Link to="/Private-investigation-services-in-Morocco" className="underline-ai special-Link">Private investigation services in Morocco</Link></li>
                        <li><Link to="/Risk-assessement-and-risk-control-services-in-Morocco" className="underline-ai special-Link">Risk assessement and risk control services in Morocco</Link></li>
                        <li><Link to="/Private-jet-and-comercials-flight-booking-in-Morocco" className="underline-ai special-Link">Private jet and comercials flight booking in Morocco</Link></li>
                        <li><Link to="/Security-drivers-and-secure-transportation-services-in-Morocco" className="underline-ai special-Link">Security drivers and secure transportation services in Morocco</Link></li>
                        <li><Link to="/Villa-renting-and-hotel-booking-in-Morocco" className="underline-ai special-Link">SVilla renting and hotel booking in Morocco</Link></li>
                    </ul>

                    
                </div>
                
            </div>
            <footer className="footer p-relative background-section">
                <div className="container">
                    <div className="footer-container">
                        <div className="d-flex align-items-center h-100">
                            {/*   <div className="column-left">
                                   <div className="footer-social p-relative">
                                      <ul>
                                          <li className="over-hidden">
                                              <a href="#" data-dsn="parallax" target="_blank"
                                                  rel="nofollow">Dribbble.</a>
                                          </li>
                                          <li className="over-hidden">
                                              <a href="#" data-dsn="parallax" target="_blank"
                                                  rel="nofollow">Behance.</a>
                                          </li>
                                          <li className="over-hidden">
                                              <a href="#" data-dsn="parallax" target="_blank"
                                                  rel="nofollow">Linkedin.</a>
                                          </li>
                                          <li className="over-hidden">
                                              <a href="#" data-dsn="parallax" target="_blank"
                                                  rel="nofollow">Twitter.</a>
                                          </li>

                                      </ul>
                                  </div> 
                                   
                              </div> */}
                            <div className="column-left">
                                <span>
                                    <h5 className="copyrighted">
                                        {t('footer.copyrighted')}
                                    </h5>
                                </span>
                            </div>
                            <div className="scroll-top animation-rotate" data-dsn="parallax">
                                <img src="assets/img/scroll_top.svg" alt="" />
                                <i className="fa fa-angle-up"></i>
                            </div>

                            <div className="column-right">
                                <span>
                                    {/* <h5>
                                        2021 © EL ADANI VIP all rights reserved
                                  </h5> */}
                                    {/*    <br /> */}
                                    <h5 className="designby">
                                        {t('footer.designer')}&nbsp;
                                        <a className="link-hover link-hover-color" data-hover-text=" IT ART" target="_blank"
                                            rel="nofollow"
                                            href="https://www.itart.io">IT ART</a>
                                    </h5>
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
