import React from 'react'
import { useTranslation } from "react-i18next"

function InterventionZone() {

    const { t } = useTranslation();

    




    return (
        <>
            <section className="intervention-zone p-relative section-margin">
                <div className="intervention-content">
                    <p className="sub-heading line-shap line-shap-after mb-15"><span className="line-bg-left title-service-index">{t('home.zone.title')}</span></p>
                    <h3 className="Big-subtitle-services">{t('home.zone.bigtitle')} </h3>
                    <p className="mb-10">{t('home.zone.para')}</p>
                </div>
                <div className="intervention-map">
                    <div class="map-img">
                        <img src={process.env.PUBLIC_URL + '/assets/images/Morocco-map2.png'} class="map-bg-img" alt="Morocco-map" />
                        <div class="city casa"></div>
                        <div class="after-casa"></div>
                        <div class="casa-shapes">
                            <div class="line" id="line1">
                                <div class="line" id="line-2">
                                    <div class="line" id="line-3">
                                        <div class="adani-logo">
                                            <img class="" id="adani-img" src={process.env.PUBLIC_URL + '/assets/images/logo.png'} />
                                        </div>
                                        <span class="" id="casa-sp">{t('home.zone.city.0')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="city rabat">
                            <span class="tooltip" >{t('home.zone.city.1')}</span>
                        </div>
                        <div class="city tanger">
                            <span class="tooltip" >{t('home.zone.city.2')}</span>
                        </div>
                        <div class="city marrackech">
                            <span class="tooltip" >{t('home.zone.city.3')}</span>
                        </div>
                        <div class="city oujda">
                            <span class="tooltip" >{t('home.zone.city.4')}</span>
                        </div>
                        <div class="city samara">
                            <span class="tooltip" >{t('home.zone.city.5')}</span>
                        </div>
                        <div class="city tantan">
                            <span class="tooltip" >{t('home.zone.city.6')}</span>
                        </div>
                        <div class="city tata">
                            <span class="tooltip" >{t('home.zone.city.7')}</span>
                        </div>
                        <div class="city fez">
                            <span class="tooltip" >{t('home.zone.city.8')}</span>
                        </div>
                        <div class="city laayoun">
                            <span class="tooltip" >{t('home.zone.city.9')}</span>
                        </div>
                        <div class="city agadir">
                            <span class="tooltip" >{t('home.zone.city.10')}</span>
                        </div>
                        <div class="city errachidia">
                            <span class="tooltip" >{t('home.zone.city.11')}</span>
                        </div>
                        <div class="city beni-mellal">
                            <span class="tooltip" >{t('home.zone.city.12')}&nbsp;{t('home.zone.city.12b')}</span>
                        </div>
                        <div class="city lagouira">
                            <span class="tooltip" >{t('home.zone.city.13')}</span>
                        </div>
                    </div>

                </div>
            </section>
            
        </>
    )
}

export default InterventionZone
