import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function Services_Index() {
    const { t } = useTranslation();
    return (
        <>
            <section className="our-blog container our-blog-classic not-filter section-margin p-relative dsn-swiper"
                data-dsn-animate="section" data-dsn-option='{"slidesPerView":3,"spaceBetween":30 }'
                data-dsn-title="our Blog">
                <div className="container-service-title container d-flex text-right flex-column align-items-center mb-70">
                <p className="sub-heading line-shap line-shap-after mb-15">
                                <span className="line-bg-left title-service-index">{t('home.services.title')}</span>

                            </p>

                    <h3 className="Big-subtitle-services">{t('home.services.grandtitle')}</h3>
                          <h3 className="subtitle-service-index"> {t('home.services.grandtitle2')} </h3>
                        
                </div>
                <div className="swiper-container ">
                    <div className="swiper-wrapper">

                        

                        <div className="swiper-slide blog-classic-item">
                            <div className=" blog-item p-relative d-flex align-items-center h-100 w-100">
                                <div className="box-meta">
                                    <div className="entry-date">

                                    </div>
                                </div>

                                <div className="box-img over-hidden">
                                    <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/global_risk_management.png" alt="Bodyguard protect client" />
                                </div>
                                <div className="box-content p-relative">

                                    <div className="box-content-body">

                                        <h4 className="title-block">
                                            <Link to="/Global_Risk_Management" className="special-Link">{t('home.services.grs.title')}</Link>
                                        </h4>
                                        <br />
                                        <ul className="list_service_ul">
                                            <li className="list_services"><p>{t('home.services.grs.li1')}  </p></li>
                                            <li className="list_services"><p>{t('home.services.grs.li2')}</p></li>
                                            <li className="list_services"><p>{t('home.services.grs.li3')} </p></li>
                                            <li className="list_services"><p>{t('home.services.grs.li4')}</p></li>
                                            <li className="list_services"><p>{t('home.services.grs.li5')}</p></li>
                                        </ul>
                                        <Link to="/Global_Risk_Management" className="link-vist p-relative mt-20 special-Link">

                                            <span className="link-vist-text">{t('home.services.buttonservices')}</span>

                                            <div className="link-vist-arrow">
                                                <svg viewBox="0 0 80 80">
                                                    <polyline points="19.89 15.25 64.03 15.25 64.03 59.33">
                                                    </polyline>
                                                    <line x1="64.03" y1="15.25" x2="14.03" y2="65.18">
                                                    </line>
                                                </svg>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="swiper-slide blog-classic-item">
                            <div className=" blog-item p-relative d-flex align-items-center h-100 w-100">
                                <div className="box-meta">
                                    <div className="entry-date">

                                    </div>
                                </div>
                                <div className="box-img over-hidden">
                                    <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/Investigation.png" alt="bodyguard with client in airport" />
                                </div>
                                <div className="box-content p-relative">

                                    <div className="box-content-body">

                                        <h4 className="title-block">
                                            <Link to="/Investigation" className="special-Link">{t('home.services.inv.title')}</Link>
                                        </h4>
                                        <br />
                                        <ul className="list_service_ul">
                                            <li className="list_services"><p>{t('home.services.inv.li1')}</p></li>
                                            <li className="list_services"><p>{t('home.services.inv.li2')}</p></li>
                                            <li className="list_services"><p>{t('home.services.inv.li3')}</p></li>
                                            <li className="list_services"><p>{t('home.services.inv.li4')}</p></li>
                                            <li className="list_services"><p>{t('home.services.inv.li5')}</p></li>
                                        </ul>
                                        <Link to="/Investigation" className="link-vist p-relative mt-20 special-Link">

                                            <span className="link-vist-text">{t('home.services.buttonservices')}</span>

                                            <div className="link-vist-arrow">
                                                <svg viewBox="0 0 80 80">
                                                    <polyline points="19.89 15.25 64.03 15.25 64.03 59.33">
                                                    </polyline>
                                                    <line x1="64.03" y1="15.25" x2="14.03" y2="65.18">
                                                    </line>
                                                </svg>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="swiper-slide blog-classic-item">
                            <div className=" blog-item p-relative d-flex align-items-center h-100 w-100">
                                <div className="box-meta">
                                    <div className="entry-date">

                                    </div>
                                </div>
                                <div className="box-img over-hidden">
                                    <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/conciergerie.jpg" alt="Investigation" />
                                </div>
                                <div className="box-content p-relative">

                                    <div className="box-content-body">

                                        <h4 className="title-block">
                                            <Link to="/Concierge_Services" className="special-Link">{t('home.services.cs.title')}</Link>
                                        </h4>
                                        <br />
                                        <ul className="list_service_ul">
                                            <li className="list_services"><p>{t('home.services.cs.li1')} </p></li>
                                            <li className="list_services"><p>{t('home.services.cs.li2')} </p></li>
                                            <li className="list_services"><p>{t('home.services.cs.li3')}</p></li>
                                            <li className="list_services"><p>{t('home.services.cs.li4')}</p></li>
                                            <li className="list_services"><p>{t('home.services.cs.li5')} </p></li>
                                        </ul>
                                        <Link to="/Concierge_Services" className="link-vist p-relative mt-20 special-Link">

                                            <span className="link-vist-text">{t('home.services.buttonservices')}</span>

                                            <div className="link-vist-arrow">
                                                <svg viewBox="0 0 80 80">
                                                    <polyline points="19.89 15.25 64.03 15.25 64.03 59.33">
                                                    </polyline>
                                                    <line x1="64.03" y1="15.25" x2="14.03" y2="65.18">
                                                    </line>
                                                </svg>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="swiper-slide blog-classic-item">
                            <div className=" blog-item p-relative d-flex align-items-center h-100 w-100">
                                <div className="box-meta">

                                </div>

                                <div className="box-img over-hidden">
                                    <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/executive_protection.png" alt="Client lux" />
                                </div>
                                <div className="box-content p-relative">

                                    <div className="box-content-body">

                                        <h4 className="title-block">
                                            <Link to="/Executive_Protection" className="special-Link">{t('home.services.ep.title')}</Link>
                                        </h4>
                                        <br />
                                        <ul className="list_service_ul">
                                            <li className="list_services"><p>{t('home.services.ep.li1')} </p></li>
                                            <li className="list_services"><p>{t('home.services.ep.li2')} </p></li>
                                            <li className="list_services"><p>{t('home.services.ep.li3')} </p></li>
                                            <li className="list_services"><p>{t('home.services.ep.li4')} </p></li>
                                            <li className="list_services"><p>{t('home.services.ep.li5')}</p></li>
                                        </ul>
                                        <Link to="/Executive_Protection" className="link-vist p-relative mt-20 special-Link">

                                            <span className="link-vist-text">{t('home.services.buttonservices')}</span>

                                            <div className="link-vist-arrow">
                                                <svg viewBox="0 0 80 80">
                                                    <polyline points="19.89 15.25 64.03 15.25 64.03 59.33">
                                                    </polyline>
                                                    <line x1="64.03" y1="15.25" x2="14.03" y2="65.18">
                                                    </line>
                                                </svg>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="dsn-pagination mt-30 dsn-container d-flex justify-content-between">
                        <div className="swiper-next">
                            <div className="next-container">
                                <div className="container-inner">
                                    <div className="triangle"></div>
                                    <svg className="circle" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24">
                                        <g className="circle-wrap" fill="none" stroke-width="1"
                                            stroke-linejoin="round" stroke-miterlimit="10">
                                            <circle cx="12" cy="12" r="10.5"></circle>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-pagination"></div>
                        <div className="swiper-prev">
                            <div className="prev-container">
                                <div className="container-inner">
                                    <div className="triangle"></div>
                                    <svg className="circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <g className="circle-wrap" fill="none" stroke-width="1"
                                            stroke-linejoin="round" stroke-miterlimit="10">
                                            <circle cx="12" cy="12" r="10.5"></circle>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services_Index
