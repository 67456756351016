import React from 'react'
import Header_EP from './Header_EP'
import Loader from '../../Loader'
import Menu from '../../Menu'
import Footer from '../../Footer'
import Contenu_EP from './Contenu_EP'
import { Helmet } from "react-helmet";


function Executive_protection() {
    return (
        <>
            <Helmet>
                <title>EL ADANI VIP SERVICES - Executive Protection  </title>
                <meta name="description" content="As a seasoned and distinguished security firm with solid experience providing executive protection and bodyguard services, EL ADANI VIP take all the security measures needed to ensure the safty of high-risk individuals from physical, financial and reputational harm." />
            <meta name="keywords" content="Executive protection,bodyguard,VIP,safety,privacy security,security measures,risk,physical safty,financial safety,reputational safty,protection,close protection,security agent,personal risk,security procedures,personal protection,security firm,security company,risk assessement,advanced planning,crimes,threats,Itinerary planning,Morocco,secure transportation,risk control"></meta>
        </Helmet> 

            <div className="wrapper">
                <Header_EP></Header_EP>
                <Contenu_EP></Contenu_EP>
            </div>       
        </>
    )
}

export default Executive_protection
