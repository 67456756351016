import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from "react-i18next"

function Thank() {
    const { t } = useTranslation();
    useEffect(() => {
        document.querySelector('body').classList.remove("dsn-show-contact")
    }, [])



    return (
        <>
            <div className="Bg-image">
               <div className="overlay-thank">
                   <div className="containerr">
                   <h2 className="thankBigTitle">{t('thank.title')}</h2>
                   <h4 className="">{t('thank.para1')}</h4>
                   <h4>{t('thank.para2')}</h4>
  
                   <Link to="/" className="mt-30 dsn-button image-zoom special-Link buttonThank" data-dsn="parallax">
                                    <span className="dsn-border border-color-reverse-color spantxtbutton special-Link"></span>
                                    {t('page404.button')}  <span>⟶</span>
                                </Link>
                   
                   </div>
               </div>
            </div>
        </>
    )
}

export default Thank
