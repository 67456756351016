import React from 'react'
import './loadingSpinner.css'

function loadingSpinner(props) {

    let classNames = props.classNames;

    return (
        <>
            <div className={`lds-spinner ${classNames}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    )
}

export default loadingSpinner
