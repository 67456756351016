import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { useFormik } from 'formik'
import { API_URL } from './../../variables'
import axios from 'axios';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';

function Content_Contact(props) {

    const { t } = useTranslation();
    const [formSubmited, setFormSubmited] = useState(false);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const navigate = useNavigate();



    const validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Please type your name';
        }

        if (!values.email) {
            errors.email = 'Please type your email';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please enter a valid email adresse';
        }

        if (!values.body) {
            errors.body = 'Please type your message';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            company: '',
            body: ''
        },
        validate,
        onSubmit: values => {
            setSubmitButtonClicked(true);
            const mailBody = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                company: values.company,
                body: values.body
            }
            axios.post(`${API_URL}/send`, mailBody)
                .then(response => {
                    if (response.data != null) {

                        setFormSubmited(true)
                        setSubmitButtonClicked(false);
                        navigate('/thank_you');
                    } else {
                        alert('sometheng went wrong, please retry later or contact us by sending an email to : contact@itart.io')
                    }
                })

        },
    });


    return (
        <>
            <div className="wrapper">

                <div className="container root-contact">
                    <div className="full-width">
                        <Map></Map>
                    </div>
                    <div className="box-contact-inner section-margin">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="form-box">
                                    <div className="line line-top"></div>
                                    <div className="line line-bottom"></div>
                                    <div className="line line-left"></div>
                                    <div className="line line-right"></div>

                                    <div className="mb-30 d-flex text-left flex-column align-items-start">
                                        <p className="sub-heading line-shap line-shap-before mb-15">
                                            <span className="line-bg-right">{t('contact.title2')}</span>
                                        </p>
                                        <h2 className="section-title  title-cap">
                                            {t('contact.bigtitle2')}
                                        </h2>
                                    </div>
                                    <p className="mb-30">
                                        {t('contact.para')}
                                    </p>

                                    <form className="contact-form" onSubmit={formik.handleSubmit}>
                                        <div className="messages"></div>
                                        <div className="input__wrap controls">
                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.yourname')}</label>
                                                    <input
                                                        id="form_name"
                                                        type="text"
                                                        name="name"
                                                        placeholder={t('contact.holderyourname')}
                                                        required="required"
                                                        data-error="name is required."
                                                        onChange={formik.handleChange}
                                                        value={formik.values.name}
                                                    />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.errors.name && formik.touched.name && (
                                                        <ul class="list-unstyled">
                                                            <li>{formik.errors.name}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.youremail')}</label>
                                                    <input
                                                        id="form_email"
                                                        type="email"
                                                        name="email"
                                                        placeholder={t('contact.holderyouremail')}
                                                        required="required"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email}
                                                    />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.errors.email && formik.touched.email && (
                                                        <ul class="list-unstyled">
                                                            <li>{formik.errors.email}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.company')}</label>
                                                    <input
                                                        id="form_company" type="company" name="company"
                                                        placeholder={t('contact.holderCompany')}
                                                        data-error="plz enter your company name"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.company}
                                                    />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.errors.company && formik.touched.company && (
                                                        <ul class="list-unstyled">
                                                            <li>{formik.errors.company}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.phone')}</label>
                                                    <input id="form_phone" type="phone" name="phone"
                                                        placeholder={t('contact.holderPhone')}
                                                        data-error="Valid phone is required."
                                                        onChange={formik.handleChange}
                                                        value={formik.values.phone}
                                                    />

                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.errors.phone && formik.touched.phone && (
                                                        <ul class="list-unstyled">
                                                            <li>{formik.errors.phone}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.yourmessage')}</label>
                                                    <textarea id="form_message" className="form-control" name="body"
                                                        placeholder={t('contact.holderyourmessage')}
                                                        required="required"
                                                        data-error="Describe your situation and requirements"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.body}
                                                    ></textarea>
                                                </div>
                                                <div className="help-block with-errors">
                                                {formik.errors.body && formik.touched.body && (
                                                    <ul class="list-unstyled">
                                                        <li>{formik.errors.body}</li>
                                                    </ul>
                                                )}
                                                </div>
                                            </div>

                                            <div className="text-right">
                                                {/* {submitButtonClicked && <LoadingSpinner></LoadingSpinner>} */}
                                                <LoadingSpinner classNames={submitButtonClicked ? "visible" : ""}></LoadingSpinner>
                                                <div className="image-zoom w-auto d-inline-block" data-dsn="parallax">
                                                    <button id="submit" name="submit" disabled={submitButtonClicked} type="submit" className="dsn-button">
                                                        <span className="dsn-border border-color-default"></span>
                                                        <span className="text-button">{t('contact.button')}</span>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div className="box-info-contact">

                                    <ul>
                                        <li>
                                            <h5 className="title-block mb-15">{t('contact.contact')}</h5>
                                            <p className="text-p ">+212 661 812 519</p>
                                            <div className="over-hidden mt-5">
                                                <a className="link-hover" data-hover-text="OPS@ELADANIVIP.COM"
                                                    href="mailto:OPS@ELADANIVIP.COM">OPS@ELADANIVIP.COM</a>
                                            </div>

                                        </li>
                                        <li>
                                            <h5 className="title-block mb-15">{t('contact.address')}</h5>
                                            <p className="text-p">{t('footer.bldv')}<br /> {t('footer.appartment')}</p>
                                        </li>
                                        {/* <li>
                                            <h5 className="title-block mb-15">{t('contact.follow')}</h5>
                                            <div className="social-item over-hidden">
                                                <a className="link-hover" data-hover-text="Instagram." href="#"
                                                    target="_blank" rel="nofollow">Instagram.</a>
                                            </div>
                                            <div className="social-item over-hidden">
                                                <a className="link-hover" data-hover-text="Facebook." href="#"
                                                    target="_blank" rel="nofollow">Facebook.</a>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <section className="next-page p-relative section-padding border-top background-section">
                    <div className="bg-circle-dotted"></div>
                    <div className="bg-circle-dotted bg-circle-dotted-right"></div>
                    <div className="container">
                        <div className="c-wapp d-flex justify-content-between">
                            <div className="d-flex flex-column">
                                <p className="sub-heading line-shap line-shap-after ">
                                    <span className="line-bg-left">
                                        Don't be weird.
                                    </span>
                                </p>
                                <h2 className="section-title max-w750 mt-15">
                                    Would you like more information or
                                    do you have a question?

                                </h2>


                            </div>

                            <div className="button-box d-flex justify-content-end align-items-center">
                                <div>
                                    <a href="work.html" className="mt-30 effect-ajax dsn-button p-relative">
                                        <span className="dsn-border-rdu "></span>Owr Work
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section> */}

            </div>
        </>
    )
}

export default Content_Contact

let Map = () => {
    return (
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.8653838943883!2d-7.621183949276069!3d33.582844280641766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d2a25d64441d%3A0x696c3df86af257e!2s2%C3%A8me%20%C3%A9tage%2C%20Appt%20N%C2%B06%D8%8C%2046%20Bd%20Mohamed%20Zerktouni%2C%20Dar-el-Beida%2020250!5e0!3m2!1sfr!2sma!4v1639588010224!5m2!1sfr!2sma" style={{width:"100%",height:"400px"}} allowfullscreen="" loading="lazy"></iframe>
    )
  }
  
  export { Map }
