import React from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from "react-i18next"

function Vehicule_Index() {
    const { t } = useTranslation();
    return (
        <>
               <section className="our-blog container our-blog-classic not-filter section-margin p-relative dsn-swiper"
                        data-dsn-animate="section" data-dsn-option='{"slidesPerView":3,"spaceBetween":30 }'
                        data-dsn-title="our Blog">
                        <div className="container d-flex text-right flex-column align-items-center mb-70">
                            <div className="">
                                <h2 className="section-title line-bg-left">{t('home.fleet.title')} </h2>
                               
                            </div>
                        </div>

                        <div className="swiper-container ">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide blog-classic-item">
                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                               
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/volswagen_touareg.png"  alt="car" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                               
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Volswagen Touareg</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="swiper-slide blog-classic-item">
                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                           
                                            </div>
                                        </div>

                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Mercedes_S_Class.png" alt="car" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                              
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Mercedes S Class</a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide blog-classic-item">
                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                              
                                            </div>
                                        </div>
                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/Mercedes_V_Class.png" alt="car" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                             
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Mercedes V Class</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide blog-classic-item">
                                    <div className=" blog-item p-relative d-flex align-items-center h-100 w-100">
                                        <div className="box-meta">
                                            <div className="entry-date">
                                              
                                            </div>
                                        </div>
                                        <div className="box-img over-hidden test-shine">
                                            <img className="cover-bg-img" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/car/range_rover_vogue.png" alt="car" />
                                        </div>
                                        <div className="box-content p-relative">

                                            <div className="box-content-body">
                                             
                                                <h4 className="title-block title-vehicule">
                                                    <a href="#">Range Rover Vogue</a>
                                                </h4>
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="dsn-pagination mt-30 dsn-container d-flex justify-content-between">
                                <div className="swiper-next">
                                    <div className="next-container">
                                        <div className="container-inner">
                                            <div className="triangle"></div>
                                            <svg className="circle" xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24">
                                                <g className="circle-wrap" fill="none" stroke-width="1"
                                                    stroke-linejoin="round" stroke-miterlimit="10">
                                                    <circle cx="12" cy="12" r="10.5"></circle>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-pagination"></div>
                                <div className="swiper-prev">
                                    <div className="prev-container">
                                        <div className="container-inner">
                                            <div className="triangle"></div>
                                            <svg className="circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <g className="circle-wrap" fill="none" stroke-width="1"
                                                    stroke-linejoin="round" stroke-miterlimit="10">
                                                    <circle cx="12" cy="12" r="10.5"></circle>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to="/Our_Fleet" className="mt-30 dsn-button image-zoom special-Link Button-fleet" data-dsn="parallax">
                                    <span className="dsn-border border-color-reverse-color"></span>
                                    {t('home.fleet.button')} <span>⟶</span>
                                </Link>
                        </div>
                        
                    </section> 
        </>
    )
}

export default Vehicule_Index
