import React from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

function Risk_assessement_and_risk_control_services_in_Morocco() {
  return <>
      <Helmet>
            <title>Risk Assessement and Risk Control Services in Morocco- EL ADANI VIP</title>
            <meta name="description" content="EL ADANI VIP provide a global risk management to individuals, businesses, government agencies and NGOs operating in Moroccan territory, including Travel risk analysis & management, crime reports, crisis management, information services and security consulting" />
            <meta name="keywords" content="Executive protection, bodyguard, VIP, safety, privacy security, security measures, risk, physical safty, financial safety, reputational safty, protection, close protection, security agent, personal risk, security procedures, personal protection, security firm, security company, risk assessement, advanced planning, crimes, threats, Itinerary planning, Morocco, secure transportation, risk control, protection officer"></meta>
        </Helmet>
              <header
                    className="header-page over-hidden p-relative header-padding-top header-padding-bottom border-bottom dsn-header-animation">
                    <div className="bg-circle-dotted"></div>
                    <div className="dsn-container">
                        <div className="content-hero p-relative d-flex flex-column h-100 dsn-hero-parallax-title">
                        <h2> <strong className="title mt-30 dsn-load-animate text-transform-upper seo-page-h1">
                        Risk assessement and risk control services in Morocco
                            </strong></h2>
                        </div>
                    </div>
                </header>  

                <div class="image-head p-relative">
                <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                    <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/close_protection.jpg" alt="Man in hotel" />
                </div>
            </div>
            <div className="wrapper">
                <br />  
                <div className="content_about seo-content">
                <h1 className="title mt-30 dsn-load-animate text-transform-upper seo-page-strong">
                        Risk assessement and risk control services in Morocco 
                            </h1>
           
                    <div className="post-content">
                        <p className="">
                        As a full-service international executive protection and threat management firm, EL ADANI VIP provide a global risk management to individuals, businesses, government agencies and NGOs operating in Moroccan territory, including Travel risk analysis & management, crime reports, crisis management, information services and security consulting, these robust security practices and procedures are essential for protecting assets and personnel, along with a thorough and well-thought-out risk assessment, they lead to efficient, crisis management plans, risk mitigation strategies and emergency response plans.
                        </p>
                    </div>
                    <div>
                        <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                            EL ADANI VIP can help you to
                                </h2>
                        <div className="post-content">
                            <ul>
                                <li>Identify and list all potential threats and give them a comparative risk value</li>
                                <li>Minimize the probability that an identified adverse event will occur</li>
                                <li>Minimize the impact of any adverse event that occurs</li>
                                <li>Take back control of the operation as quickly and efficiently as possible</li>
                                <li>Return to normal operating state as quickly and efficiently as possible</li>
                            </ul>
                        </div>
                    </div>
                    
                        <div className="p-services">
                        <Link to="/About" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                        <span className="dsn-border border-color-reverse-color"></span>
                        <span>⟵</span> Home Page
                        </Link>
                        <Link to="/contact" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                            <span className="dsn-border border-color-reverse-color"></span>
                            CONTACT US NOW
                        </Link>
                        
                </div></div>


            </div>
  </>;
}

export default Risk_assessement_and_risk_control_services_in_Morocco;
