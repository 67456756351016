import React from 'react'
import { useTranslation } from "react-i18next"

function Header_CS() {
    const { t } = useTranslation();

    return (
        <>
            <header
                    className="header-page over-hidden p-relative header-padding-top header-padding-bottom border-bottom dsn-header-animation">
                    <div className="bg-circle-dotted"></div>
                    <div className="dsn-container">
                        <div className="content-hero p-relative d-flex flex-column h-100 dsn-hero-parallax-title">
                            <p className="subtitle p-relative line-shap  line-shap-after">
                                <span className="pl-10 pr-10 background-main dsn-load-animate">{t('services.name')}</span>
                            </p>
                            <h1 className="title mt-30 dsn-load-animate text-transform-upper">
                            {t('services.cs.title')}<br/> 
                            </h1>
                        </div>
                    </div>
                </header>  
        </>
    )
}

export default Header_CS
