import React from 'react'

function Loader() {
    return (
        <>
        <div className="preloader">
            <span className="percent ">0</span>
            <span className="loading-text text-uppercase">Loading ...</span>
            <div className="preloader-bar">
                <div className="preloader-progress"></div>
            </div>

            <h1 className="title v-middle">
                <span className="text-strok">EL ADANI VIP</span>
                <span className="text-fill">EL ADANI VIP</span>
            </h1>
        </div>
        </>
    )
}

export default Loader
