import React from 'react'
import Loader from '../Loader'
import Menu from '../Menu'
import Header_Fleet from './Header_Fleet'
import Content_Fleet from './Content_Fleet'
import Footer from '../Footer'
import { Helmet } from "react-helmet";


function Our_Fleet() {
    return (
        <>
            <Helmet>
                <title>EL ADANI VIP - OUR FLEET  </title>
                
            </Helmet>

            <div className="wrapper">
                <Header_Fleet></Header_Fleet>
                <Content_Fleet></Content_Fleet>
            </div>
        </>
    )
}

export default Our_Fleet
