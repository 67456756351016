import React, { useRef, useEffect } from "react";

export default function Video({ isMuted }) {
    const refVideo = useRef(null);

    useEffect(() => {
        if (!refVideo.current) {
            return;
        }

        if (isMuted) {
            //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
            refVideo.current.defaultMuted = true;
            refVideo.current.muted = true;
        }

       // refVideo.current.srcObject = src;
    }, []);

    return (
            <video
                src={process.env.PUBLIC_URL + '/assets/videos/HeaderVideo.mp4'}
                ref={refVideo}
                autoPlay
                playsInline={true} //FIX iOS black screen
                webkit-playsinline={true}
                loop
                id="vid"
            />
            
    );
}