import Loader from './components/Loader';
import Menu from './components/Menu';
import Slider from './components/Slider';
import Index from './components/Index';
import About from './components/About';
import Contact from './components/Contact';
import Executive_protection from './components/Services/Executive_Protection/Executive_protection';
import Concierge_Services from './components/Services/Concierge_Services/Concierge_Services';
import Investigation from './components/Services/Investigation/Investigation';
import Our_Fleet from './components/Our_fleet/Our_Fleet';
import { BrowserRouter , Route , Routes } from 'react-router-dom'
import {Switch} from 'react-router-dom'
import Footer from './components/Footer'
import Cursor from './components/Cursor';
import Scroll from './components/Scroll';
import International_Travel_Security from './components/Services/Global risk management/Global_Risk_Management';
import ContactModal from './components/ContactModal';
import LightBox from './components/LightBox';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import LanguageSwitcher from './components/LanguageSwitcher';
import Thank from './components/Contact/Thank'
import Example from './components/Seo_Pages/Example';
import Erreur from './components/Erreur';
import Ysf from './components/Ysf';
import Executive_protection_company_in_Morocco from './components/Seo_Pages/Executive_protection_company_in_Morocco'
import Close_protection_services_in_Morocco from './components/Seo_Pages/Close_protection_services_in_Morocco';
import Events_security_services_in_Morocco from './components/Seo_Pages/Events_security_services_in_Morocco';
import VIPs_bodyguard_services_in_Morocco from './components/Seo_Pages/VIPs_bodyguard_services_in_Morocco';
import VIPs_concierge_services_in_Morocco from './components/Seo_Pages/VIPs_concierge_services_in_Morocco';
import Private_investigation_services_in_Morocco from './components/Seo_Pages/Private_investigation_services_in_Morocco';
import Risk_assessement_and_risk_control_services_in_Morocco from './components/Seo_Pages/Risk_assessement_and_risk_control_services_in_Morocco';
import Plan_Website from './components/Seo_Pages/Plan_Website';
import Private_jet_and_comercials_flight_booking_in_Morocco from './components/Seo_Pages/Private_jet_and_comercials_flight_booking_in_Morocco';
import Security_drivers_and_secure_transportation_services_in_Morocco from './components/Seo_Pages/Security_drivers_and_secure_transportation_services_in_Morocco';
import Villa_renting_and_hotel_booking_in_Morocco from './components/Seo_Pages/Villa_renting_and_hotel_booking_in_Morocco';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs : ['en','fr'],
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    },
    detection : {
      order: ['cookie','htmlTag',  'localStorage', 'navigator', 'path', 'subdomain'],
      caches:['cookie'],
    },
    backend:{
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react:{ useSuspense : false }
    
  });

function App(props) {
  const { t } = useTranslation();
  
 
  
  // const script2 = document.createElement("script");
  // script2.src = externalJS2;
  // document.body.appendChild(script2);
  //
  

  // const script3 = document.createElement("script");
  // script3.src = externalJS3;
  // document.body.appendChild(script3);
  

  // const script4 = document.createElement("script");
  // script4.src = externalJS4;
  // document.body.appendChild(script4);
  
  
  return (
    <>
     {/* <Index></Index> */}
     {/* <About></About> */}
     {/* <Contact></Contact> */}
     {/* <Executive_protection></Executive_protection> */}
     {/* <Concierge_Services></Concierge_Services> */}
     {/* <Investigation></Investigation> */}
     {/* <Our_Fleet></Our_Fleet> */}
     <BrowserRouter>
        <Loader />
        
        {/* <h1>{t('about.title')}</h1>
        <p>{t('paragraph')}</p> */}
        <Menu />
        {/* <LanguageSwitcher/> */}
        <main class="main-root">
          <div id="dsn-scrollbar">
            <div class="main-content">
              <Routes>
                  <Route path="/" element={<Index/>} />
                  <Route path="/About" element={<About/>} />
                  <Route path="/Contact" element={<Contact/>} />
                  <Route path="/Concierge_Services" element={<Concierge_Services/>} />
                  <Route path="/Executive_Protection" element={<Executive_protection/>} />
                  <Route path="/Investigation" element={<Investigation/>} />
                  <Route path="/Global_Risk_Management" element={<International_Travel_Security/>} />
                  <Route path="/Our_Fleet" element={<Our_Fleet/>} />
                  <Route path="/thank_you" element={<Thank/>} />
                  <Route path="/example" element={<Example/>} />
                  <Route path="/404" element={<Erreur/>} />
                  <Route path='*' exact={true} element={<Erreur/>} />
                  <Route path="/Sitemap" element={<Plan_Website/>} /> 
                  <Route path="/Executive-protection-company-in-Morocco" element={<Executive_protection_company_in_Morocco/>} />
                  <Route path="/Close-protection-services-in-Morocco" element={<Close_protection_services_in_Morocco/>} />
                  <Route path="/Events-security-services-in-Morocco" element={<Events_security_services_in_Morocco/>} /> 
                  <Route path="/VIPs-bodyguard-services-in-Morocco" element={<VIPs_bodyguard_services_in_Morocco/>} />
                  <Route path="/VIPs-concierge-services-in-Morocco" element={<VIPs_concierge_services_in_Morocco/>} />
                  <Route path="/Private-investigation-services-in-Morocco" element={<Private_investigation_services_in_Morocco/>} />
                  <Route path="/Risk-assessement-and-risk-control-services-in-Morocco" element={<Risk_assessement_and_risk_control_services_in_Morocco/>} />
                  <Route path="/Private-jet-and-comercials-flight-booking-in-Morocco" element={<Private_jet_and_comercials_flight_booking_in_Morocco/>} />
                  <Route path="/Security-drivers-and-secure-transportation-services-in-Morocco" element={<Security_drivers_and_secure_transportation_services_in_Morocco/>} />
                  <Route path="/Villa-renting-and-hotel-booking-in-Morocco" element={<Villa_renting_and_hotel_booking_in_Morocco/>} />
              </Routes>
              <Footer />
            </div>
          </div>
          <LightBox/>
          <ContactModal/>
        </main>

        
        <Scroll />
        <Cursor />
     </BrowserRouter>
    </>
  );

  }
  
export default App;
