import React from 'react'
import {Link} from 'react-router-dom'
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from "react-i18next"

function Menu() {
    const { t } = useTranslation();
    // const btn = document.getElementById('not-working')
    // console.log('btn is : ',btn)

    const notWorkingClicked = (e)=>{
        alert('hi');
        e.preventDefault();
    }
    const goToHome = () => {
        window.location = "/";
    }
    //.addEventListener('click', (e) => e.preventDefault())

    //document.getElementById('not-working')
    //.addEventListener('keypress', (e) => e.preventDefault())
    
    return (
        <>
            <div className="site-header dsn-container dsn-load-animate">
                <div className="extend-container d-flex w-100 align-items-baseline justify-content-between align-items-end">
                    <div className="inner-header p-relative">
                        <div className="main-logo">
                            {/* <Link to="index.html" data-dsn="parallax">
                                <img class="light-logo"
                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                    data-dsn-src="assets/img/logo.png" alt="" />
                                   <img class="dark-logo"
                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                    data-dsn-src="assets/img/logo-dark.png" alt="" />
                            </Link> */}
                            <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="Logo_EladaniVip" height="80px" width="80px" onClick={()=>goToHome()} className="logo-img"/>                          
                        </div>
                    </div>
                    <LanguageSwitcher/>
                    <div className="menu-icon d-flex align-items-baseline">
                        <div className="text-menu p-relative  font-heading text-transform-upper">
                            <div className="p-absolute text-button">Menu</div>
                            <div className="p-absolute text-open">{t('menu.open')}</div>
                            <div className="p-absolute text-close">{t('menu.close')}</div>
                        </div>
                        <div className="icon-m" data-dsn="parallax" data-dsn-move="10">
                            <span className="menu-icon-line p-relative d-inline-block icon-top"></span>
                            <span className="menu-icon-line p-relative d-inline-block icon-center"></span>
                            <span className="menu-icon-line p-relative d-block icon-bottom"></span>
                        </div>
                    </div>
                    <nav className="accent-menu dsn-container main-navigation p-absolute  w-100  d-flex align-items-baseline ">
                        <div className="menu-cover-title">Menu</div>
                        <ul className="extend-container p-relative d-flex flex-column justify-content-center h-100">


                            <li>
                                <Link to="/">

                                    <span className="dsn-title-menu">{t('menu.home')}</span>
                                    <span className="dsn-bg-arrow"></span>
                                </Link>
                            </li>


                            <li>
                                <Link to="/About">

                                    <span className="dsn-title-menu">{t('menu.aboutus')}</span>
                                    <span className="dsn-bg-arrow"></span>
                                </Link>
                            </li>

                            
                        
                            <li className="dsn-drop-down">
                                <a className="user-no-selection not-working" disabled>

                                    <span className="dsn-title-menu">{t('menu.ourservices.name')}</span>
                                    <span className="dsn-bg-arrow"></span>
                                </a>


                                <ul>
                                    <li className="dsn-back-menu">
                                        <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            data-dsn-src="assets/img/left-chevron.svg" alt="" />
                                        <span className="dsn-title-menu">{t('menu.ourservices.name')}</span>
                                    </li>

                                    <li>
                                        <Link to="/Executive_Protection">
                                            <span className="dsn-title-menu services-menuu">{t('menu.ourservices.ep')}</span>
                                        </Link>
                                    </li>

                                    

                                    <li>
                                        <Link to="/Global_Risk_Management">
                                            <span className="dsn-title-menu services-menuu">{t('menu.ourservices.grm')}</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/Investigation">
                                            <span className="dsn-title-menu services-menuu">{t('menu.ourservices.inv')}</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/Concierge_Services">
                                            <span className="dsn-title-menu services-menuu">{t('menu.ourservices.cs')}</span>
                                        </Link>
                                    </li>

                                </ul>

                            </li>
                            
                            
                            <li>
                                <Link to="/Our_Fleet">

                                    <span className="dsn-title-menu">{t('menu.ourfleet')}</span>
                                    <span className="dsn-bg-arrow"></span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/Contact">

                                    <span className="dsn-title-menu">{t('menu.contact')}</span>
                                    <span className="dsn-bg-arrow"></span>
                                </Link>
                            </li>


                        </ul>
                        <div className="container-content  p-absolute h-100 left-60 d-flex flex-column justify-content-center">
                            <div className="nav__info">
                                <div className="nav-content">
                                    <p className="title-line">
                                    {t('menu.address')}</p>
                                    <p>
                                        46 BD ZERKTOUNI<br />
                                        2ème étage appt 6 CASABLANCA, MAROC</p>
                                </div>
                                <div className="nav-content">
                                    <p className="title-line">
                                    {t('menu.contact')}</p>
                                    <p className="links over-hidden">
                                        <a href="tel:+212661812519" data-hover-text="+212 661 812 519" className="link-hover">+212 661 812 519</a>
                                    </p>
                                    <p className="links  over-hidden">
                                        <a href="mailto:ops@eladanivip.com" data-hover-text="ops@eladanivip.com" mailto="ops@eladanivip.com" className="link-hover">ops@eladanivip.com</a>
                                    </p>
                                </div>
                            </div>
                           {/*  <div className="nav-social nav-content">
                                <div className="nav-social-inner p-relative">
                                    <p className="title-line">
                                        Follow us</p>
                                    <ul>
                                        <li>
                                            <Link to="#" target="_blank" rel="nofollow">Dribbble.
                                                <div className="icon-circle"></div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" target="_blank" rel="nofollow">Behance.
                                                <div className="icon-circle"></div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" target="_blank" rel="nofollow">Linkedin.
                                                <div className="icon-circle">
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" target="_blank" rel="nofollow">Twitter.
                                                <div className="icon-circle"></div>
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Menu
