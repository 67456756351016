import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { API_URL } from './../variables'
import axios from 'axios'
import LoadingSpinner from './loadingSpinner/LoadingSpinner'
import { useTranslation } from "react-i18next"

function ContactModal() {
    const { t } = useTranslation();
    const [formSubmited, setFormSubmited] = useState(false);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const navigate = useNavigate();

    const validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Please type your name';
        }

        if (!values.email) {
            errors.email = 'Please type your email';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Please enter a valid email adresse';
        }

        if (!values.body) {
            errors.body = 'Please type your message';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            company: '',
            body: ''
        },
        validate,
        onSubmit: values => {
            setSubmitButtonClicked(true);
            const mailBody = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                company: values.company,
                body: values.body
            }
            axios.post(`${API_URL}/send`, mailBody)
                .then(response => {
                    if (response.data != null) {

                        setFormSubmited(true)
                        setSubmitButtonClicked(false);
                        navigate('/thank_you');
                    } else {
                        alert('sometheng went wrong, please retry later or contact us by sending an email to : contact@itart.io')
                    }
                })

        },
    });



    return (
        <>
            <div className="contact-btn">
                <div className="contact-btn-txt">{t('contact.contact')}</div>
            </div>
            <div className="contact-modal background-section">
                <div className="contact-container h-100 w-100  border-right">
                    <div className="box-contact-inner">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="form-box">
                                    <div className="line line-top"></div>
                                    <div className="line line-bottom"></div>
                                    <div className="line line-left"></div>
                                    <div className="line line-right"></div>

                                    <div className="mb-30 d-flex text-left flex-column align-items-start">
                                        <p className="sub-heading line-shap line-shap-before mb-15">
                                            <span className="line-bg-right">{t('contact.title2')}</span>
                                        </p>
                                        <h2 className="section-title  title-cap">
                                            {t('contact.bigtitle2')}
                                        </h2>
                                    </div>

                                    <form className="contact-form" onSubmit={formik.handleSubmit}>
                                        <div className="messages"></div>
                                        <div className="input__wrap controls">
                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.yourname')}</label>
                                                    <input id="form_name" type="text" name="name"
                                                        placeholder={t('contact.holderyourname')} required="required"
                                                        data-error="name is required."
                                                        onChange={formik.handleChange}
                                                        value={formik.values.name}
                                                    />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.errors.name && formik.touched.name && (
                                                        <ul class="list-unstyled">
                                                            <li>{formik.errors.name}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.youremail')}</label>
                                                    <input id="form_email" type="email" name="email"
                                                        placeholder={t('contact.holderyouremail')} required="required"
                                                        data-error="Valid email is required."
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email}
                                                    />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.errors.email && formik.touched.email && (
                                                        <ul class="list-unstyled">
                                                            <li>{formik.errors.email}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.company')}</label>
                                                    <input
                                                        id="form_company" type="company" name="company"
                                                        placeholder={t('contact.holderCompany')}
                                                        data-error="plz enter your company name"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.company}
                                                    />
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.errors.company && formik.touched.company && (
                                                        <ul class="list-unstyled">
                                                            <li>{formik.errors.company}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.phone')}</label>
                                                    <input id="form_phone" type="phone" name="phone"
                                                        placeholder={t('contact.holderPhone')}
                                                        data-error="Valid phone is required."
                                                        onChange={formik.handleChange}
                                                        value={formik.values.phone}
                                                    />

                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.errors.phone && formik.touched.phone && (
                                                        <ul class="list-unstyled">
                                                            <li>{formik.errors.phone}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <div className="entry-box">
                                                    <label>{t('contact.yourmessage')}</label>
                                                    <textarea id="form_message" className="form-control" name="body"
                                                        placeholder={t('contact.holderyourmessage')} required="required"
                                                        data-error="Please,leave us a message."
                                                        onChange={formik.handleChange}
                                                        value={formik.values.body}

                                                    ></textarea>
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.errors.body && formik.touched.body && (
                                                        <ul class="list-unstyled">
                                                            <li>{formik.errors.body}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="text-right">
                                                <LoadingSpinner classNames={submitButtonClicked ? "visible" : ""}></LoadingSpinner>
                                                <div className="image-zoom w-auto d-inline-block" data-dsn="parallax">
                                                    <input type="submit" value={t('contact.button')} disabled={submitButtonClicked}
                                                        className="dsn-button background-main border-radius" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div className="box-info-contact">
                                    <ul>
                                        <li>
                                            <h5 className="title-block mb-15">{t('contact.contact')}</h5>
                                            <p className="links over-hidden">
                                                <a href="tel:+212661812519" data-hover-text="+212 661 812 519" style={{color:"white"}} className="link-hover">+212 661 812 519</a>
                                            </p>
                                            <p className="links  over-hidden">
                                                <a href="mailto:ops@eladanivip.com" data-hover-text="ops@eladanivip.com" style={{color:"white"}} mailto="ops@eladanivip.com" className="link-hover">ops@eladanivip.com</a>
                                            </p>
                                        </li>
                                        <li>
                                            <h5 className="title-block mb-15">{t('contact.address')}</h5>
                                            <p className="text-p">{t('footer.bldv')} <br /> {t('footer.appartment')}</p>
                                        </li>
                                        {/* <li>
                                            <h5 className="title-block mb-15">Follow</h5>
                                            <div className="social-item over-hidden">
                                                <a className="link-hover" data-hover-text="Instagram." href="#" target="_blank"
                                                    rel="nofollow">Instagram.</a>
                                            </div>
                                            <div className="social-item over-hidden">
                                                <a className="link-hover" data-hover-text="Facebook." href="#" target="_blank"
                                                    rel="nofollow">Facebook.</a>
                                            </div>
                                            <div className="social-item over-hidden">
                                                <a className="link-hover" data-hover-text="YouTube." href="#" target="_blank"
                                                    rel="nofollow">YouTube.</a>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactModal
