import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function Contenu_EP() {
    const { t } = useTranslation();
    return (
        <>
            <div class="image-head p-relative">
                <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                    <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/close_protection.jpg" alt="Hotel and car" />
                </div>
            </div>
            <br />
            <div className="content_about">
                <p className="sub-heading line-shap line-shap-after mb-15">
                    <span className="line-bg-left font-small-title"> {t('services.ep.title2')}</span>
                </p>
                <br />
                <div className="post-content">
                    <p>
                        {t('services.ep.para1')}
                        <br /><br />
                        {t('services.ep.para2')}
                        <br /><br />
                        {t('services.ep.para3')}
                        <br /><br />
                        {t('services.ep.para4')}
                        <br /><br />
                    </p>
                </div>
                <br />



                {/* <div className="big-container">
                    <div className="container">
                        <div className="row fill-right-container">
                            <div className="col-lg-6">
                                <div className="box-info pt-60 pb-60">

                                    <h5 className="title-epp">Close Protection (CPO - BG - EP )</h5>
                                    <br />
                                    <p>
                                        Ensuring the protection of our clients against threats and crimes, mobility and reputation risks. by providing them with experienced, well-trained protection agents who discreetly adapt to their professional status and their lifestyle.
                                        <br />  <br />
                                        Our protection officers take care of:
                                    </p>
                                    <br />
                                    <ol>
                                        <li className="Investigation-li"><p>Risk assessment and minimization</p></li>
                                        <li className="Investigation-li"><p>Itinerary planning</p></li>
                                        <li className="Investigation-li"><p>Verification of vehicles, sites and land</p></li>
                                        <li className="Investigation-li"><p>vigilance and attention to danger</p></li>
                                    </ol>
                                    <br />
                                    <p>
                                        The selection of our staff goes through a pre-employment survey which includes psychological, intellectual and physical tests, in order to ensure their compliance with international standards and their mastery of the following skills:
                                    </p>
                                    <br />
                                    <ol>
                                        <li className="Investigation-li"><p>The special attention to detail.</p></li>
                                        <li className="Investigation-li"><p>the ability to blend discreetly and professionally into the VIP lifestyle.</p></li>
                                        <li className="Investigation-li"><p>Risk assessment and awareness of dangers.</p></li>
                                        <li className="Investigation-li"><p>The ability to maintain good physical shape.</p></li>
                                        <li className="Investigation-li"><p>The ability to work in hostile and high risk environments.</p></li>
                                    </ol>

                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="background-mask p-20 p-absolute h-100 w-100">
                                    <div className="line line-top"></div>
                                    <div className="line line-bottom"></div>
                                    <div className="line line-left"></div>
                                    <div className="line line-right"></div>

                                    <div className="img-box h-100">
                                        <div className="img-container h-100 before-z-index" data-dsn-grid="move-up"
                                            data-overlay="3">
                                            <img className="img-p1" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                data-dsn-src="assets/images/eladani_ep_P1.png" alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="container">
                        <div className="row fill-right-container reverse-container">

                            <div className="col-lg-6">
                                <div className="background-mask p-20 p-absolute h-100 w-100">
                                    <div className="line line-top"></div>
                                    <div className="line line-bottom"></div>
                                    <div className="line line-left"></div>
                                    <div className="line line-right"></div>

                                    <div className="img-box h-100">
                                        <div className="img-container h-100 before-z-index" data-dsn-grid="move-up"
                                            data-overlay="3">
                                            <img className="img-p1" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                data-dsn-src="assets/images/Chauffeur_bodyguard.png" alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="col-lg-6">
                                <div className="box-info pt-60 pb-60">

                                    <h5 className="title-epp">Secure transportation with security drivers : </h5>

                                    <p>
                                        As an Executive Protection Company operating across Morocco. We provide clients with reliable secure ground transportation in conjunction with our executive protection services and airport meet and greet.
                                        <br />  <br />
                                        Our secure transportation service encompasses in-depth knowledge and a measured level of skill to conduct route surveys, recognize and develop safe havens, create alternate-route plans, develop emergency evacuation plans, and select the safest route to a destination in order to mitigate the risks and ensure that the clients arrives at their destination on time and in a comfortable manner.
                                        <br />   <br />
                                        Our mission is to provide our clients an exceptional, luxury and comfortable service that comes with attention to detail and customer service standards that are second to none, by offering an excellent selection of luxury vehicles combined with knowledgeable, experienced, and accommodating professional security drivers alongside with our support staff 24/7- 365
                                        <br />   <br />
                                        Our security drivers are trained in a variety of safety and security techniques, they meet all strict background and safety checks and maintain exceptional driving record providing our clients peace of mind while in our care.
                                        <br />   <br />
                                        We provide the most luxurious service with an extensive range of luxurious, marked, and fully equipped vehicles that offer luxury, comfort and safety, Our fleet includes :
                                        <br />   <br />
                                        <ol>
                                            <li className="Investigation-li"><p>Mercedes ( S class - V class - E class )</p></li>
                                            <li className="Investigation-li"><p>Mercedes Vito </p></li>
                                            <li className="Investigation-li"><p>Mercedes sprinter</p></li>
                                            <li className="Investigation-li"><p>Rang rover Vogue </p></li>
                                            <li className="Investigation-li button-li-p2"><p>See the entire fleet --) </p></li>
                                        </ol>
                                    </p>
                                </div>
                            </div>


                        </div>

                    </div>
                </div> */}
            </div>
            <div className="big-container">
                <div className="container">


                    <div className="card">
                        <div className="imgBx">
                            <img className="img-p1" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-dsn-src="assets/images/EP_Close_protection.png" alt="" />
                        </div>
                        <div className="content">
                            <div className="para">
                                <h5 className="title-epp">{t('services.ep.section1.title')}</h5>
                                <div className="scrolable scrolable-p1">
                                    <div className="scrolling-area">
                                        <p>
                                            {t('services.ep.section1.para1')}
                                            <br />  <br />
                                            {t('services.ep.section1.para2')}
                                        </p>
                                        <br />
                                        <ol>
                                            <li className="Investigation-li"><p>{t('services.ep.section1.li1')}</p></li>
                                            <li className="Investigation-li"><p>{t('services.ep.section1.li2')}</p></li>
                                            <li className="Investigation-li"><p>{t('services.ep.section1.li3')}</p></li>
                                            <li className="Investigation-li"><p>{t('services.ep.section1.li4')}</p></li>
                                        </ol>
                                        <br />
                                        <p>
                                            {t('services.ep.section1.para3')}
                                        </p>
                                        <br />
                                        <ol>
                                            <li className="Investigation-li"><p>{t('services.ep.section1.li5')}</p></li>
                                            <li className="Investigation-li"><p>{t('services.ep.section1.li6')}</p></li>
                                            <li className="Investigation-li"><p>{t('services.ep.section1.li7')}</p></li>
                                            <li className="Investigation-li"><p>{t('services.ep.section1.li8')}</p></li>
                                            <li className="Investigation-li"><p>{t('services.ep.section1.li9')}</p></li>
                                        </ol>
                                    </div></div>
                            </div></div>
                    </div>

                    <div className="card">
                        <div className="imgBx">
                            <img className="img-p1" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-dsn-src="assets/images/Chauffeur.png" alt="" />
                        </div>
                        <div className="content">
                            <div className="para">
                                <h5 className="title-epp">{t('services.ep.section2.title')}</h5>
                                <div className="scrolable scrolable-p2">
                                    <div className="scrolling-area">
                                        <p>
                                            {t('services.ep.section2.para1')}
                                            <br />  <br />
                                            {t('services.ep.section2.para2')}
                                            <br />   <br />
                                            {t('services.ep.section2.para3')}
                                            <br />   <br />
                                            {t('services.ep.section2.para4')}
                                            <br />   <br />
                                            {t('services.ep.section2.para5')}
                                            <br />   <br />
                                            <ol>
                                                <li className="Investigation-li"><p>{t('services.ep.section2.li1')}</p></li>
                                                <li className="Investigation-li"><p>{t('services.ep.section2.li2')}</p></li>
                                                <li className="Investigation-li"><p>{t('services.ep.section2.li3')}</p></li>
                                                <li className="Investigation-li"><p>{t('services.ep.section2.li4')}</p></li>
                                                <li className="Investigation-li button-li-p2"><Link to="/Our_Fleet"><p> {t('services.ep.section2.li5')} <span>⟶</span> </p></Link></li>
                                            </ol>
                                            <br />
                                            {t('services.ep.section2.para6')} <br /> <br />
                                            {t('services.ep.section2.para7')}

                                        </p>
                                    </div>
                                </div></div> </div>
                    </div>

                    <div className="card pad-card">
                        <div className="imgBx">
                            <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-dsn-src="assets/images/Events security.png" alt="" />
                        </div>
                        <div className="content">
                            <div className="para">
                                <h5 className="title-epp">{t('services.ep.section3.title')} </h5> <br />

                                <div className="scrolable scrolable-p3">
                                    <div className="scrolling-area">
                                        <p>
                                            {t('services.ep.section3.para1')}
                                            <br /><br />
                                            {t('services.ep.section3.para2')}
                                            <br /> <br />
                                            {t('services.ep.section3.para3')}
                                            <br /><br />
                                            {t('services.ep.section3.para4')}
                                        </p>
                                    </div></div></div></div>
                    </div>

                   
                </div>
            </div>

        </>
    )
}

export default Contenu_EP
