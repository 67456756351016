import React from 'react'

function Scroll() {
    return (
        <>
            <div className="scroll-to-top">
                <img src="assets/img/scroll_top.svg" alt="" />
                <div className="box-numper">
                    <span>10%</span>
                </div>
            </div>
        </>
    )
}

export default Scroll
