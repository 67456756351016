import React from 'react'
import Content_ITS from './Content_GRM'
import Header_ITS from './Header_GRM'
import { Helmet } from "react-helmet";


function International_Travel_Security() {
    return (
        <>

            <Helmet>
                <title>EL ADANI VIP SERVICES - Global Risk Management  </title>
                <meta name="description" content="EL ADANI VIP provide a global risk management to individuals, businesses, government agencies and NGOs operating in Moroccan territory, including Travel risk analysis & management, crime reports, crisis management, information services and security consulting" />
                <meta name="keywords" content="Executive protection, bodyguard, VIP, safety, privacy security, security measures, risk, physical safty, financial safety, reputational safty, protection, close protection, security agent, personal risk, security procedures, personal protection, security firm, security company, risk assessement, advanced planning, crimes, threats, Itinerary planning, Morocco, secure transportation, risk control, protection officer"></meta>
            </Helmet>

            <div className="wrapper">
                <Header_ITS></Header_ITS>
                <Content_ITS></Content_ITS>
            </div>
        </>
    )
}

export default International_Travel_Security
