import React from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

function VIPs_bodyguard_services_in_Morocco() {
    return <>
        <Helmet>
            <title>VIPs Bodyguard Services in Morocco - EL ADANI VIP</title>
            <meta name="description" content="El ADANI VIP provide close protection services for government agencies , wealthy individuals and families, celebrities and VIP personalities, Our mission is to identify possible threats, mitigate risks and respond to crises affecting organizations and individuals" />
            <meta name="keywords" content="Executive protection, bodyguard, VIP, safety, privacy security, security measures, risk, physical safty, financial safety, reputational safty, protection, close protection, security agent, personal risk, security procedures, personal protection, security firm, security company, risk assessement, advanced planning, crimes, threats, Itinerary planning, Morocco, secure transportation, risk control, protection officer"></meta>
        </Helmet>
        <header
            className="header-page over-hidden p-relative header-padding-top header-padding-bottom border-bottom dsn-header-animation">
            <div className="bg-circle-dotted"></div>
            <div className="dsn-container">
                <div className="content-hero p-relative d-flex flex-column h-100 dsn-hero-parallax-title">
                    <h2> <strong className="title mt-30 dsn-load-animate text-transform-upper seo-page-h1">
                        VIPs bodyguard services in Morocco
                    </strong></h2>
                </div>
            </div>
        </header>

        <div class="image-head p-relative">
            <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/close_protection.jpg" alt="Man in hotel" />
            </div>
        </div>
        <div>
            <br />
            <div className="content_about seo-content">
                <h1 className="title mt-30 dsn-load-animate text-transform-upper seo-page-strong">
                    VIPs bodyguard services in Morocco
                </h1>

                <div className="post-content">
                    <p className="">
                        In this ever-changing and complex world, public figures, high-net-worth individuals and VIPs are more and more exposed to elevated personal risk, in the age of internet, these high profile individuals, and due to their celebrity status, employement and wealth, attract not just their share of admirers, but also stalkers, obsessive fans, kidnappers, extortioners, and others who might wish harm upon them or their loved ones.
                    </p>
                    <p>
                        standard security procedures are no longer sufficient to mitigate the risks, and a more spesialized form of protection with developed equipement and material that offers a more attentive and responsive approach to personal protection is recomended to maintain their safty and privacy.
                    </p>
                    <p>
                        as a seasoned and distinguished security firm with solid experirnce providing executive protection and bodyguard services, EL ADANI VIP take all the security measures needed to ensure the safty of high-risk individuals from physical, financial and reputational harm, with strategic risk assessement, advanced planning, and experienced security teams.
                    </p>
                </div>
                <div>
                    <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                        Our VIPs bodyguards Mission
                    </h2>
                    <div className="post-content">
                        <p className="">
                            Our mission is ensuring the protection of our clients against threats and crimes, mobility and reputation risks. by providing them with experienced, well-trained protection agents who discreetly adapt to their professional status and their lifestyle.                            </p>
                        <p>Our protection officers take care of:</p>
                        <ul>
                            <li>Risk assessment and minimization</li>
                            <li>Itinerary planning</li>
                            <li>Verification of vehicles, sites and land</li>
                            <li>vigilance and attention to danger</li>
                        </ul>
                    </div>
                </div>
                <div className="wrapper">
                    <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                        Our VIPs bodyguards Qualities
                    </h2>
                    <div className="post-content">
                        <p className="">
                            The selection of our staff goes through a pre-employment survey which includes psychological, intellectual and physical tests, in order to ensure their compliance with international standards and their mastery of the following skills:
                        </p>
                        <ul>
                            <li>The special attention to detail</li>
                            <li>the ability to blend discreetly and professionally into the VIP lifestyle</li>
                            <li>Risk assessment and awareness of dangers</li>
                            <li>The ability to maintain good physical shape</li>
                            <li>The ability to work in hostile and high risk environments</li>
                        </ul>

                    </div>
                </div>
                <div className="p-services">

                    <Link to="/About" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                        <span className="dsn-border border-color-reverse-color"></span>
                        <span>⟵</span> Home Page
                    </Link>
                    <Link to="/contact" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                        <span className="dsn-border border-color-reverse-color"></span>
                        CONTACT US NOW
                    </Link>

                </div>
            </div>

        </div>
    </>;
}

export default VIPs_bodyguard_services_in_Morocco;
