import React from 'react'
import Video from '../Video'

function Header() {
    return (
        <>
            <header className="main-slider  has-horizontal p-relative w-100 h-100-v dsn-header-animation">
                <div className="content-slider p-relative w-100 h-100 over-hidden v-dark-head">
                    <div className="bg-container  dsn-hero-parallax-img p-relative w-100 h-100">
                        {/* <div id="myWrap">
                            <div className="overlay1"></div>
                            <video id="myVid" src={process.env.PUBLIC_URL + '/assets/videos/HeaderVideo.mp4'}
                                autoPlay muted loop>
                            </video>

                            <div id="myContent"></div>
                        </div> */}
                            
                        <section className="ShowCase">
                        <div className="overlay1"></div>
                             <div className="header-logo-container">
                                <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="" />
                                <h2>WE TAKE CARE OF EVERYTHING</h2>
                                <p className="subtitleHeader">SAFETY - PRIVACY - LUXURY</p>
                            </div> 
                            {/* <video src={process.env.PUBLIC_URL + '/assets/videos/HeaderVideo.mp4'} muted="muted" loop autoPlay playsInline="playsinline"></video> */}
                            <Video isMuted={true} ></Video>
                        </section>


                    </div>
                </div>


            </header>
        </>
    )
}

export default Header
