import React,{useEffect,useState} from 'react'
import Cookies from 'js-cookie'
import 'flag-icon-css/css/flag-icon.min.css'
import i18next from "i18next"


const languages = [
    {
        code:'fr',
        name:'Français',
        country_code:'fr'
    },
    {
        code:'en',
        name:'English',
        country_code:'gb'
    }
    // {
    //     code:'ar',
    //     name:'العربية',
    //     country_code:'ma',
    //     dir:'rtl '
    // }
]

function LanguageSwitcher() {

    const [currentLangCode, setcurrentLangCode] = useState(Cookies.get('i18next') || 'en')
    const currentLang = languages.find((l) => l.code === currentLangCode)
    
    const switchLanguage = (code)=>{
        i18next.changeLanguage(code);
        setcurrentLangCode(Cookies.get('i18next') || 'en')
    }
    
    return (
        <>
            <div className="language_switcher">
                <div className="selected-language">
                    <span className={`flag-icon mr-2 flag-icon-${currentLang.country_code}`}></span>
                
                    <i className="fas fa-sort-down dropdown-icon"></i>
                </div>
                <ul>
                    {languages.map(({code,name,country_code})=>{
                      

                        return country_code !== currentLang.country_code ?
                        <a href="" className="special-Link language-switcher-link">
                            <li key={code}>
                                <div className="language" onClick={()=>switchLanguage(code)}> 
                                    <span className={`flag-icon mr-2 flag-icon-${country_code}`}></span>
                                    
                                </div>
                            </li>
                        </a>
                       :
                       ''
                    })}
                </ul>
            </div>
        </>
    )
}

export default LanguageSwitcher
