import React from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from "react-i18next"

function Paralex_Contact() {
    const { t } = useTranslation();
    return (
        <>
                             <div className="box-seat box-seat-parallax section-margin">

<div className="inner-img h-100-v" data-dsn-grid="move-up" data-overlay="2">
    <img className="transform-3d has-bigger-scale img-home-paralex" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
    data-dsn-src="assets/images/bodyguard_with_client.png" alt="Bodyguard with client"/>
</div>
<div className="pro-text background-section box-padding" data-dsn-grid="move-section"
    data-dsn-responsive="tablet">
    <div className="mb-30 d-flex text-left flex-column align-items-start">
        <p className="sub-heading line-shap line-shap-after mb-15">
            <span className="line-bg-right"> {t('home.contact.title')}</span>
        </p>
        <h2 className="section-title">{t('home.contact.subtitle')} </h2>

    </div>


    <p className="mb-10">
    {t('home.contact.para1')}
    </p>
    <p>
    {t('home.contact.para2')}</p>

    <Link to="/Contact" className="mt-30 dsn-button image-zoom special-Link" data-dsn="parallax">
        <span className="dsn-border border-color-reverse-color"></span>
        {t('home.contact.button')} <span>⟶</span>
    </Link>

    <p className="sm-p mt-15 theme-color">
    {t('home.contact.smalltitle')}
    </p>
</div>

</div> 
        </>
    )
}

export default Paralex_Contact
