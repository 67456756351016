import React from 'react'
import { useTranslation } from "react-i18next"

function Our_Bodyguard() {
    const { t } = useTranslation();

    return (
        <>
                               <section className="services with-line dsn-swiper p-relative section-margin"
                        data-dsn-title="our Services" data-dsn-option='{"slidesPerView":3,"spaceBetween":0}'>
                        <div className="container mb-70 d-flex text-center flex-column align-items-center">
                            <p className="sub-heading line-shap line-shap-after mb-15">
                                <span className="line-bg-left">{t('about.bodyguard.title')}</span>

                            </p>
                            <h2 className="section-title">{t('about.bodyguard.bigtitle')} <br/> {t('about.bodyguard.bigtitle1')}</h2>
                        </div>

                        <div className="services-item container">
                            <div className="swiper-container">
                                <div className="swiper-wrapper">




                                    <div className="swiper-slide">
                                        <div className="services-item-inner" data-swiper-parallax-scale="0.85">
                                            <div className="corner corner-left-top"></div>

                                            <div className="corner corner-right-bottom"></div>
                                            <div className="services-content  background-section ">

                                                <div className="icon">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/our_bodyguard/1.png'} alt="Task"  />                          
                                                </div>
                                                <div className="service-description">
                                                   
                                                    <p className="paragraph-about">{t('about.bodyguard.para1')}</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>



                                    <div className="swiper-slide">
                                        <div className="services-item-inner" data-swiper-parallax-scale="0.85">
                                            <div className="corner corner-left-top"></div>

                                            <div className="corner corner-right-bottom"></div>
                                            <div className="services-content  background-section ">

                                                <div className="icon">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/our_bodyguard/2.png'} alt="gym"  />                          
                                                </div>
                                                <div className="service-description">
                                                   
                                                    <p className="paragraph-about">{t('about.bodyguard.para2')}</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>



                                    <div className="swiper-slide">
                                        <div className="services-item-inner" data-swiper-parallax-scale="0.85">
                                            <div className="corner corner-left-top"></div>

                                            <div className="corner corner-right-bottom"></div>
                                            <div className="services-content  background-section ">

                                                <div className="icon">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/our_bodyguard/3.png'} alt="rating"  />                          
                                                </div>
                                                <div className="service-description">
                                                   
                                                    <p className="paragraph-about">{t('about.bodyguard.para3')} <br /></p>
                                                    {/* {t('about.bodyguard.para3bis')} */}
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    

                                    <div className="swiper-slide">
                                        <div className="services-item-inner" data-swiper-parallax-scale="0.85">
                                            <div className="corner corner-left-top"></div>

                                            <div className="corner corner-right-bottom"></div>
                                            <div className="services-content  background-section ">

                                                <div className="icon">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/our_bodyguard/4.png'} alt="rating"  />                          
                                                </div>
                                                <div className="service-description">
                                                   
                                                    <p className="paragraph-about">{t('about.bodyguard.para4')}</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>


                                    <div className="swiper-slide">
                                        <div className="services-item-inner" data-swiper-parallax-scale="0.85">
                                            <div className="corner corner-left-top"></div>

                                            <div className="corner corner-right-bottom"></div>
                                            <div className="services-content  background-section ">

                                                <div className="icon">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/our_bodyguard/5.png'} alt="rating"  />                          
                                                </div>
                                                <div className="service-description">
                                                   
                                                    <p className="paragraph-about">{t('about.bodyguard.para5')}</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>


                                    <div className="swiper-slide">
                                        <div className="services-item-inner" data-swiper-parallax-scale="0.85">
                                            <div className="corner corner-left-top"></div>

                                            <div className="corner corner-right-bottom"></div>
                                            <div className="services-content  background-section ">

                                                <div className="icon">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/our_bodyguard/6.png'} alt="rating"  />                          
                                                </div>
                                                <div className="service-description">
                                                   
                                                    <p className="paragraph-about">{t('about.bodyguard.para6')}</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="dsn-pagination mt-30 dsn-container d-flex justify-content-between">
                                    <div className="swiper-next">
                                        <div className="next-container">
                                            <div className="container-inner">
                                                <div className="triangle"></div>
                                                <svg className="circle" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24">
                                                    <g className="circle-wrap" fill="none" stroke-width="1"
                                                        stroke-linejoin="round" stroke-miterlimit="10">
                                                        <circle cx="12" cy="12" r="10.5"></circle>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-pagination"></div>
                                    <div className="swiper-prev">
                                        <div className="prev-container">
                                            <div className="container-inner">
                                                <div className="triangle"></div>
                                                <svg className="circle" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24">
                                                    <g className="circle-wrap" fill="none" stroke-width="1"
                                                        stroke-linejoin="round" stroke-miterlimit="10">
                                                        <circle cx="12" cy="12" r="10.5"></circle>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> 
        </>
    )
}

export default Our_Bodyguard
