import React from 'react'
import Content_About from './About/Content_About'
import Header_About from './About/Header_About'
import Our_Bodyguard from './About/Our_Bodyguard'
import Footer from './Footer'
import Menu from './Menu'
import Loader from './Loader'
import Cursor from './Cursor'
import Scroll from './Scroll'
import { Helmet } from 'react-helmet'
function About() {
    return (
        <>
               <Helmet>
                <title> ABOUT EL ADANI VIP - Moroccan Executive Protection Company  </title>
                <meta name="description" content="EL ADANI VIP is a Moroccan company well positioned to provide an integrated suite of products and services that address growing concerns related to combined threats, crimes, terrorist threats, mobility and reputational risks for celebrities and VIP personalities." />
                <meta name="keywords" content="Executive protection, bodyguard, VIP, safety, privacy security, security measures, risk, physical safty, financial safety, reputational safty, protection, close protection, security agent, personal risk, security procedures, personal protection, security firm, security company, risk assessement, advanced planning, crimes, threats, Itinerary planning, Morocco, secure transportation, risk control, protection officer"></meta>
            </Helmet>      
            <Header_About></Header_About>
            <div class="wrapper">
                <Content_About></Content_About>
                <Our_Bodyguard></Our_Bodyguard>
            </div>
              
        </>
    )
}

export default About
