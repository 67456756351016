import React from 'react'
import Loader from '../../Loader'
import Menu from '../../Menu'
import Footer from '../../Footer'
import Header_Investigation from './Header_Investigation'
import Content_Investigation from './Content_Investigation'
import { Helmet } from "react-helmet";


function Investigation() {
    return (
        <>

            <Helmet>
                <title>EL ADANI VIP SERVICES - Ivestigation  </title>
                <meta name="description" content="EL ADANI VIP offers a wide range of investigation advice, services, and information inquiry to meet both organizational and personal needs with advanced surveillance equipment, state-of-the-art technology, and experienced staff" />
                <meta name="keywords" content="Executive protection,Background check,Private investigation,morality investigation,Financial investigations,Unfair competition,Location of person and witness,Competitive analysis,detective"></meta>
            </Helmet>

            <div className="wrapper">
               <Header_Investigation></Header_Investigation>
               <Content_Investigation></Content_Investigation>
            </div>
        </>
    )
}

export default Investigation
