import React from 'react'
import { useTranslation } from "react-i18next"

function Contenu_CS() {
    const { t } = useTranslation();

    return (
        <>
             <div class="image-head p-relative">
                <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                    <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/conciergerie-3.jpg" alt="Man in hotel" />
                </div>
            </div>
            <div>
                <br />  
                <div className="content_about">
                    <p className="sub-heading line-shap line-shap-after mb-15">
                        <span className="line-bg-left font-small-title">{t('services.cs.title')}</span>
                    </p>
                    <br />
                    <div className="post-content">
                        <p className="p-services">
                        {t('services.cs.para1')}
                        <br /><br />
                        {t('services.cs.para2')}                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Contenu_CS
