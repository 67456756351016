import React from 'react'

function LightBox() {
    return (
        <>
            <div className="img-modal" id="img-modal">
                <span className="close" id="close-modal">x</span>
                <div className="modal-content">
                    <img className="modal-img" src={process.env.PUBLIC_URL + 'assets/images/Fleet_Vehicle.png'} />
                    <h3 className="modal-title" id="lightbox-title">Mercedes</h3>
                </div>
            </div>
        </>
    )
}

export default LightBox
