import React from 'react'
import Loader from '../../Loader'
import Menu from '../../Menu'
import Footer from '../../Footer'
import Header_CS from './Header_CS'
import Contenu_CS from './Contenu_CS'
import { Helmet } from "react-helmet";

function Concierge_Services() {
    return (
        <>
            <Helmet>
                <title>EL ADANI VIP SERVICES - Concierge Services  </title>
                <meta name="description" content="EL ADANI VIP provide a global concierge services in the largest cities of Morocco. like rental of luxury villas, Restaurant reservations, Laundry service, Cleaning service, Cooking and childcare at your home, Provision of a butler, a translator, a city, mountain and desert guide…" />
                <meta name="Keywords" content="villa renting,villas for rent,hotel rooms booking,accommodation,luxury villas,houses for rent,5 stars hotel,Restaurant reservations, Laundry service, Cleaning service, Cooking and childcare at your home, Provision of a butler,translator,City guide,guide,mountain guide,desert guide,Excursion,Tracking,Golf,Moroccan cooking lessons, concierge services" />
            </Helmet>
            <div className="wrapper">
                <Header_CS></Header_CS>
                <Contenu_CS></Contenu_CS>
            </div>
        
        </>
    )
}

export default Concierge_Services
