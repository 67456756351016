import React from 'react'
import { useTranslation } from "react-i18next"

function Header_Contact() {
    const { t } = useTranslation();
    return (
        <>
            <header
                className="header-page over-hidden p-relative header-padding-top header-padding-bottom dsn-header-animation big-image">
                    <div className="overlay-contact"></div>
                <div className="bg-circle-dotted-contact"></div>
                <div className="dsn-container">
                    <div
                        className="content-hero p-relative d-flex align-items-center text-center flex-column h-100 dsn-hero-parallax-title ">
                        <p className="subtitle p-relative line-shap  ">
                            <span className="pl-10 pr-10 background-section dsn-load-animate">{t('contact.title')}</span>
                        </p>
                        <h1 className="title mt-20 dsn-load-animate">{t('contact.bigtitle')}</h1>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header_Contact
