import React from 'react'
import Header_Contact from './Contact/Header_Contact'
import Footer from './Footer'
import Header from './Index/Header'
import Loader from './Loader'
import Menu from './Menu'
import Content_Contact from './Contact/Content_Contact'
import Scroll from './Scroll'
import Cursor from './Cursor'
import { Helmet } from "react-helmet";


function Contact() {
    return (
        <>



                            <Helmet>
                                <title>EL ADANI VIP - Executive protection company - CONTACT US  </title>
                                <meta name="description" content="As a seasoned and distinguished security firm with solid experience providing executive protection and bodyguard services, EL ADANI VIP take all the security measures needed to ensure the safty of high-risk individuals from physical, financial and reputational harm." />
                                <meta name="keywords" content="Executive protection,bodyguard,VIP,safety,privacy security,security measures,risk,physical safty,financial safety,reputational safty,protection,close protection,security agent,personal risk,security procedures,personal protection,security firm,security company,risk assessement,advanced planning,crimes,threats,Itinerary planning,Morocco,secure transportation,risk control"></meta>
                            </Helmet>


                            <Header_Contact></Header_Contact>
                            <Content_Contact></Content_Contact>
                      




        </>
    )
}

export default Contact
