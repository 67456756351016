import React from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

function Events_security_services_in_Morocco() {
    return <>
        <Helmet>
            <title>Events Security Services in Morocco - EL ADANI VIP</title>
            <meta name="description" content="EL ADANI VIP plan high-level security strategies that depends on the size, scope, location, and type of events, and includes advanced threat assessment, planning and monitoring, large crowd control, and immediate crisis response." />
            <meta name="keywords" content="Executive protection,bodyguard,VIP,safety,privacy security,security measures,risk,physical safty,financial safety,reputational safty,protection,close protection,security agent,personal risk,security procedures,personal protection,security firm,security company,risk assessement,advanced planning,crimes,threats,Itinerary planning,Morocco,secure transportation,risk control,protection officer,event,attendees,staff,safe environment,Event security,security guard,security strategy,threat assessment,crowd control,festivals,fairs,parties and seminars "></meta>
        </Helmet>
        <header
            className="header-page over-hidden p-relative header-padding-top header-padding-bottom border-bottom dsn-header-animation">
            <div className="bg-circle-dotted"></div>
            <div className="dsn-container">
                <div className="content-hero p-relative d-flex flex-column h-100 dsn-hero-parallax-title">
                    <h2> <strong className="title mt-30 dsn-load-animate text-transform-upper seo-page-h1">
                        Events security services in Morocco
                    </strong></h2>
                </div>
            </div>
        </header>

        <div class="image-head p-relative">
            <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/lux_client.png" alt="Man in hotel" />
            </div>
        </div>
        <div className="wrapper">
            <br />
            <div className="content_about seo-content">
                <h1 className="title mt-30 dsn-load-animate text-transform-upper seo-page-strong">
                    Events security services in Morocco
                </h1>

                <div className="post-content">
                    <p className="">
                        Security has become one of the greatest concerns for event professionals, as much is being done around the world to compromise security at events, small or major, professional or entertaining, today, all kind of events needs advanced planning and a strong security presence to ensure a safe environment for VIPs, attendees and staff.                    </p>
                </div>
                <div>
                    <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                        Our security strategies
                    </h2>
                    <div className="post-content">
                        <p className="">
                            Event security entails far more than just appointing a few security guards at the entrances or having CCTV cameras around the venue, The process is quite complex and requires extensive coordination and communication.                        </p>
                        <p>
                            EL ADANI VIP plan high-level security strategies that depends on the size, scope, location, and type of events, and includes advanced threat assessment, planning and monitoring, large crowd control, and immediate crisis response, We are ready to deal with all sorts of different incidents, including attacks, injuries, property damage, crowd control, guests looking to cause trouble and many other unexpected situations.
                        </p>
                    </div>
                </div>
                <div>
                    <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                        EL ADANI VIP : your event security agency in morocco
                    </h2>
                    <div className="post-content">
                        <p className="">
                            From festivals to fairs, company parties and seminars, our trained personnel is alert and discreet and ensure that the event takes place in complete safety and in a good atmosphere.                        </p>
                    </div>
                </div>
                <div className="p-services">
                    <Link to="/About" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                        <span className="dsn-border border-color-reverse-color"></span>
                        <span>⟵</span>  Home Page
                    </Link>
                    <Link to="/contact" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                        <span className="dsn-border border-color-reverse-color"></span>
                         CONTACT US
                    </Link>
                    <br />
                    

                </div></div>


        </div>
    </>;
}

export default Events_security_services_in_Morocco;
