import React from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

function Example() {
    const { t } = useTranslation();

    return (
        <>
        <Helmet>
            <title>Example</title>
            <meta name="description" content="" />
        </Helmet>
              <header
                    className="header-page over-hidden p-relative header-padding-top header-padding-bottom border-bottom dsn-header-animation">
                    <div className="bg-circle-dotted"></div>
                    <div className="dsn-container">
                        <div className="content-hero p-relative d-flex flex-column h-100 dsn-hero-parallax-title">
                        <h2> <strong className="title mt-30 dsn-load-animate text-transform-upper seo-page-h1">
                        Grand title keyword in SEO 
                            </strong></h2>
                        </div>
                    </div>
                </header>  

                <div class="image-head p-relative">
                <div class="before-z-index" data-dsn-grid="move-up" data-overlay="5">
                    <img class="cover-bg-img has-bigger-scale" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-dsn-src="assets/images/lux_client.png" alt="Man in hotel" />
                </div>
            </div>
            <div>
                <br />  
                <div className="content_about">
                <h1 className="title mt-30 dsn-load-animate text-transform-upper seo-page-strong">
                            Grand title keyword in SEO 
                            </h1>
           
                    <div className="post-content">
                        <p className="">
                        EL ADANI VIP offers a wide range of investigation advice, services, and information inquiry to meet both organizational and personal needs with advanced surveillance equipment, state-of-the-art technology, and experienced staff.EL ADANI VIP offers a wide range of investigation advice, services, and information inquiry to meet both organizational and personal needs with advanced surveillance equipment, state-of-the-art technology, and experienced staff.
                        </p>
                    </div>
                    <div>
                        <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                            Grand title keyword in SEO 
                                </h2>
                        <div className="post-content">
                            <p className="">
                            EL ADANI VIP offers a wide range of investigation advice, services, and information inquiry to meet both organizational and personal needs with advanced surveillance equipment, state-of-the-art technology, and experienced staff.EL ADANI VIP offers a wide range of investigation advice, services, and information inquiry to meet both organizational and personal needs with advanced surveillance equipment, state-of-the-art technology, and experienced staff.
                            </p>
                        </div>
                    </div>
                    <div>
                        <h2 className="title mt-30 dsn-load-animate text-transform-upper seo-page-h2">
                            Grand title keyword in SEO 
                                </h2>
                        <div className="post-content">
                            <p className="">
                            EL ADANI VIP offers a wide range of investigation advice, services, and information inquiry to meet both organizational and personal needs with advanced surveillance equipment, state-of-the-art technology, and experienced staff.EL ADANI VIP offers a wide range of investigation advice, services, and information inquiry to meet both organizational and personal needs with advanced surveillance equipment, state-of-the-art technology, and experienced staff.
                            </p>
                        </div>
                    </div>
                        <div className="p-services">
                        <Link to="/contact" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                                    <span className="dsn-border border-color-reverse-color"></span>
                                    <span>⟵</span> Page Contact
                    </Link>
                    <br />
                    <Link to="/About" className="mt-30 dsn-button image-zoom special-Link " data-dsn="parallax">
                                    <span className="dsn-border border-color-reverse-color"></span>
                                    <span>⟵</span> Page Home 
                    </Link>
                    
                </div></div>


            </div>
        </>
    )
}

export default Example
